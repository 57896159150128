<script lang="ts">
	import { createEventDispatcher } from "svelte";

    export let name: string;
    export let label: string;

    const dispatch = createEventDispatcher();

    const sendValue = (event: Event) => {
        let element: HTMLInputElement = event.target as HTMLInputElement;
        let toggleButtonValue: boolean = element.checked; 

        dispatch("message", {
            toggleButtonValue
        });
    };
</script>

<div class="form-check form-switch">
    <input class="form-check-input" type="checkbox" id={name} {name} on:click={sendValue} />
    <label class="form-check-label" for={name}>
        {label}
    </label>
</div>