<script lang="ts">
	import { createEventDispatcher } from "svelte";
	import type { MenuItem } from "$lib/components/pages/HelpPage/HelpMenu/HelpMenu";
	import Lang from '$lib/utilities/Lang.svelte';

	
	export let title: string = "";
	export let children: MenuItem[] = [];
	export let filename: string = "";
	let open = false;
	
	const dispatch = createEventDispatcher();
	
	const handleMenuItemClick = (event: Event) => {
		open = !open;
		
		let button: HTMLInputElement = event.target as HTMLInputElement;
		if(children.length === 0 || filename) {
			dispatch("select-menu-item", {
				filename,
				button
			});
		}
	}
</script>

<li>
	{#if filename || children.length > 0}
		<button on:click={handleMenuItemClick} >
			{#if children.length > 0}
				<span>
					{#if open}
						<i class='fa-sharp fa-solid fa-chevron-down' />
					{:else}
						<i class='fa-sharp fa-solid fa-chevron-up' />
					{/if}
				</span>
			{/if}
			<Lang p="help" w={title} />
		</button>
	{:else}
		<button style="color: red;">No component file for {title} was found!</button>
	{/if}
	
	{#if children.length > 0}
		<ul class:open>
			{#each children as child}
				<svelte:self {...child} on:select-menu-item />
			{/each}
		</ul>
	{/if}
</li>