export interface MenuItem {
    title: string;
    children?: MenuItem[];
    filename?: string;
}

/* 
    Constraints:
    In order for this menu work correctly, add the property "filename" to the lowest level menu item.
    It will not break if no filename is given, but no content will be loaded either.
    For the rules on dynamic imports, we can only go one level deep: 
    https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
*/
export const menu: MenuItem[] = [
    {
        title: "filters",
        filename: "filters"
    },
    {
        title: "table.settings",
        filename: "table.settings"
    },
    {
        title: "results",
        filename: "results",
        children: [
            {
                title: "results.main.results",
                filename: "results.main.results"
            },{
                title: "results.constructs.questions",
                filename: "results.constructs.questions"
            },{
                title: "results.question.results",
                filename: "results.question.results"
            },{
                title: "results.table",
                filename: "results.table"
            }
        ]
    },
    {
        title: "response",
        filename: "response"
    },
    {
        title: "extra.information",
        filename: "info",
        children: [
            {
                title: "extra.information.actions.interventions",
                filename: "actions.interventions"
            },
            {
                title: "extra.information.nes",
                filename: "nes"
            },
            {
                title: "extra.information.response.rate",
                filename: "response.rate"
            },
        ]
    },
    {
        title: "support",
        filename: "support"
    }
];