<script lang="ts">
	import { currentLanguage } from '$lib/stores/languageStore';

	export let selectedDynamicComponent: any = 'home';
</script>

<div class="card card-help">
	<div class="card-body">
		<div class="row">
			<div class="col-12">
				<div class="content-item-container">
					<div class="content-item">
						{#await import(`$lib/components/pages/HelpPage/HelpContent/pages/${selectedDynamicComponent}.${$currentLanguage.code}.svelte`) then value}
							<svelte:component this={value.default} />
						{/await}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
