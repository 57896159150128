<script lang="ts">
	import { afterUpdate, createEventDispatcher, onMount } from 'svelte';
	import {
		allReportDataForFetchedGroups,
		dashboardFocusReportSlug
	} from '../../stores/dataStore.ts';
	import { currentLanguage } from '../../stores/languageStore.js';
	import Tiles from './resultTiles.svelte';
	import Grid from './resultsTabGrid.svelte';

	onMount(() => {
		//console.log('onMount resultsTabHome');
		//console.log('availableReports', $allReportDataForFetchedGroups[$dashboardFocusReportSlug]);
		//switchTab('tab1');
		createTabs();
	});

	const _dispatch = createEventDispatcher();

	let tabsToShow: any[] = [];
	let objectWithSortedConstructs = [];
	let _tilesToShow = {};

	function createTabs() {
		// Use optional chaining to safely access nested properties
		// This will prevent runtime errors if any intermediate property is undefined or null
		const detailsToBaseTabsOn =
			$allReportDataForFetchedGroups?.[$dashboardFocusReportSlug]?.details?.constructs;

		//console.log('detailsToBaseTabsOn', detailsToBaseTabsOn);
		//filter constructs based on show property
		let constructs = Object.entries(detailsToBaseTabsOn);
		let filteredConstructs = constructs.filter((entry) => entry[1].show);
		// Sort the filtered array based on 'orderBy'
		let sortedConstructs = filteredConstructs.sort((a, b) => a[1].orderBy - b[1].orderBy);
		// Convert the sorted array back into an object
		objectWithSortedConstructs = Object.fromEntries(sortedConstructs);
		//console.log(objectWithSortedConstructs);

		//console.log('>>>>>>>>>>>>>>>>>>>constructs', Object.keys(objectWithSortedConstructs));
		tabsToShow = [];
		let count = 0;
		if (objectWithSortedConstructs && typeof objectWithSortedConstructs === 'object') {
			tabsToShow = Object.keys(objectWithSortedConstructs)
				.map((construct) => {
					let constructObject = objectWithSortedConstructs[construct];

					// Check if constructObject is valid before accessing its properties
					if (constructObject && typeof constructObject === 'object') {
						count++; // Increment the count for each valid constructObject


						let tableID: string = constructObject.id;
						if(tableID.includes(' ')){
							//console.error('ID in construct contains one or more spaces, remove them from data');
							//console.log(tableID);
							//to prevent a loading error
							tableID = tableID.replace(/\s/g, '');
						}


						return {
							id: 'tab' + count,
							keyInDataObject: tableID,
							name: constructObject.name,
							definition: constructObject.definition
						};
					}

					// Return null if constructObject is not valid
					return null;
				})
				.filter(Boolean); // Filter out null values
		}
		//console.log('>>>>>>>>>>>>>>>>>>>tabsToShow', tabsToShow);
		// Check if reportDetails is defined and has the 'context' and 'company' properties
		// if (reportDetails?.context?.company) {
		// 	organizationName = reportDetails.context.company.name;
		// }

		Object.keys(objectWithSortedConstructs['results']['topics']).filter(k => objectWithSortedConstructs['results']['topics'][k].show === true).forEach(k => _tilesToShow[k] = objectWithSortedConstructs['results']['topics'][k]);
	}

	let activeTab = 'tab1'; // Default active tab
	
	function switchTab(tabId) {
		activeTab = tabId;
		_dispatch('switchTabEvent', { activeTabId: activeTab, tabs: tabsToShow});
	}

	afterUpdate(() => _dispatch('switchTabEvent', { activeTabId: activeTab, tabs: tabsToShow}));
</script>

<div class="row result-tabs-container">
	<div class="col-9">
		<ul id="result-tabs" class="nav nav-tabs" role="tablist">
			{#each tabsToShow as tab}
				<li class="nav-item" role="presentation">
					<a
						href="#"
						class="nav-link"
						class:active={tab.id === activeTab}
						on:click|preventDefault={() => switchTab(tab.id)}
					>
						{tab.name[$currentLanguage.code]}
					</a>
				</li>
			{/each}
		</ul>
	</div>
	<div class="col-3">
		<div class="table-constraint">
			<p class="badge badge-soft-nochange"> 
				{#if $currentLanguage.code === 'nl'}
					Er wordt geen data getoond bij&nbsp;<em>n</em>&nbsp;kleiner dan 5 <!-- TODO translate proper -->
				{:else if $currentLanguage.code === 'en'}
					No data is shown if&nbsp;<em>n</em>&nbsp;less than 5
				{:else if $currentLanguage.code === 'de'}
					Wenn <em>n</em>&nbsp; kleiner als 5 ist, werden keine Daten angezeigt
				{:else if $currentLanguage.code === 'fr' }
					Aucune donnée n'est affichée si <em>n</em>&nbsp; est inférieur à 5
				{/if}
			</p>
		</div>
	</div>
</div>

<div class="tab-content">
	{#each tabsToShow as tab}
		<div class="tab-pane" class:active={tab.id === activeTab} id={tab.id} role="tabpanel">
			{#if tab.keyInDataObject === 'results'}
				<Tiles tilesToMakeForTheseTopics={_tilesToShow} />
			{:else}
				<Grid focus={tab.keyInDataObject} tableID={tab.keyInDataObject} />
			{/if}
		</div>
	{/each}
</div>

<style>
	.nav-link {
		color: #495057;
	}

	.tab .nav-tabs .nav-link.active {
		background: #e2e8ee !important;
		color: #293042 !important;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
	}
	.tab .tab-content {
		box-shadow: 0.1rem 0.2rem 0.2rem rgb(0 0 0 / 15%);
	}

	.tab-pane {
		min-height: 500px;
	}
	.alignLeft {
		text-align: left;
	}
	.tab-pane {
		display: none;
	}

	.tab-pane.active {
		display: block;
	}
</style>
