<script lang="ts">
    import HelpMenu from "$lib/components/pages/HelpPage/HelpMenu/HelpMenu.svelte";
    import HelpContent from "$lib/components/pages/HelpPage/HelpContent/HelpContent.svelte";

	let selectedDynamicComponent: string;

	const removeCurrentlySelectedMenuItem = () => {
        document.querySelector(".help-card-menu button.selected")?.classList.remove("selected");
    };
    
    const updateSelectedMenuItem = (event: CustomEvent) => {
        removeCurrentlySelectedMenuItem();
        const selectedMenuItemButton = event.detail.button as HTMLElement;
        selectedMenuItemButton.classList.add("selected");
		selectedDynamicComponent = event.detail.filename;
    }
</script>

<main class="content">
	<div class="container-fluid container-help">
		<div class="row">
			<div class="col-3">
				<HelpMenu on:select-menu-item={updateSelectedMenuItem}></HelpMenu>
			</div>
			<div class="col-9">
				<HelpContent bind:selectedDynamicComponent></HelpContent>
			</div>
		</div>
	</div>
</main>
