<script lang="ts">
	import { onMount } from 'svelte';
	import { allTranslations, currentLanguage } from '../../stores/languageStore.js';
	import { fetchReportDataForUnitsAndSubgroups } from '../../utilities/getDataHelperFunctions.js';
	import { generateGridContent } from '../../utilities/aggridHelperFunctions.js';
	import {
		availableReports,
		organizationalUnitsSelected,
		subGroupsSelected,
		historicBenchmarkMode,
		historicBenchmarkSlug,
		heatmapMode,
		allReportDataForFetchedGroups,
		dashboardFocusReportSlug,
		modalDetailsVisible,
		dataIsCurrentlyLoading,
		serverEnvironment,
		agGridLicense,
		exportTableOptions,
		mainNavTabSelected
	} from '../../stores/dataStore.js';
	
	import {
		createIDStringForElement,
		getDisplayReportName
	} from '../../utilities/formatDataHelperFunctions.js'
	import Lang from '../../utilities/Lang.svelte';
	import * as d3 from 'd3';

	import { get } from 'svelte/store';

	import * as testGrid from 'ag-grid-enterprise';
	import { Grid } from 'ag-grid-enterprise';
	import { LicenseManager } from 'ag-grid-enterprise';

	LicenseManager.setLicenseKey(agGridLicense);
	import 'ag-grid-community/styles/ag-grid.css';
	import 'ag-grid-community/styles/ag-theme-alpine.css';

	export let focus; //defined in resultatenTab.svelte
	export let tableID = ''; //defined in resultatenTab.svelte
	//Added to see if grid is already present
	let gridApi = null;
	let gridColumnApi = null;

	onMount(() => {
		let idString = '#' + tableID;
		$exportTableOptions[$mainNavTabSelected][tableID] = { exportExcel: function() { export_excel(); }, exportCSV: function() { export_csv(); }, maximize: function() { fitTableToView();}, minimize: function() { fitContentToView();}};

		//remove spaces from idString
		if(idString.includes(' ')){
			console.error('ID in construct contains one or more spaces, remove them from data');
			//console.log(tableID);
			//to prevent a loading error
			idString = idString.replace(/\s/g, '');
		}

		const gridDiv = document.querySelector(idString);
		//console.log(gridDiv);
		//let test = testGrid.createGrid(gridDiv, gridOptions)
		new Grid(gridDiv, gridOptions);
		//gridApi = AgGrid.createGrid(gridDiv, gridOptions);
		
	});

	let gridOptions = {
		rowData: [{ construct: 'test' }],
		columnDefs: [{ field: 'construct' }],
		suppressFieldDotNotation: true,
		// autoSizeStrategy: {
        // 	type: 'fitCellContents',
		// 	defaultMinWidth: 100,
		// 	columnLimits: [
        //     {
        //         colId: 'Topic',
        //         minWidth: 150
        //     },
		// 	{
        //         colId: 'Visual',
        //         minWidth: 300
        //     }
        // ]
	    // },
		animateRows: true,
		defaultColDef: {
			sortable: true,
			resizable: true,
			unSortIcon: true,
			menuTabs: ['generalMenuTab'],
			suppressMenu: true,
			wrapHeaderText: true,
			autoHeaderHeight: true
		},
	
		onFirstDataRendered: (params) => {
			gridApi = params.api;
			gridColumnApi = params.columnApi;
			params.api.expandAll();
		},
		onCellClicked: (event) => {
			//console.log('Cell was clicked');
			//console.log(event);

			// Getting the row id
			const rowId = event.data.id;
			//console.log('Row Id: ', rowId);

			// Getting the column id
			const colId = event.column.userProvidedColDef.groupId;
			//console.log('Column Id: ', colId);
			if(colId!==undefined && event.value!=="geen data"  &&dataIsCurrentlyLoading){
				openModal(rowId, colId);
			} else {
				//console.log('No team clicked or no data or data is loading');
			}
		
		},
		onCellMouseOver: (event) => {
			//console.log('Cell was hovered');
			//console.log(event);
			const rowId = event.data.id;
			//console.log('Row Id: ', rowId);
			// Getting the column id
			const colId = event.column.userProvidedColDef.field;
			//console.log('Column Id: ', colId);

			let circleID = createIDStringForElement(colId) + '_' + createIDStringForElement(rowId) + '_circle';
			let circleClass = createIDStringForElement(colId) + '_circle';
			// //console.log(circleID)
			d3.selectAll('.' + circleClass)
				// .style("background-color", "red")
				.style('transform-origin', 'center center')
				.style('transition', 'all 0.3s ease');

			d3.select('#' + circleID)
				// .style("background-color", "black")
				.style('transform-origin', 'center center')
				.style('transform', 'scale(1.5) translateY(-50%)')
				.style('transition', 'all 0.3s ease');
		},
		onCellMouseOut: (event) => {
			const rowId = event.data.id;
			const colId = event.column.userProvidedColDef.field;

			 let circleID = createIDStringForElement(colId) + '_' + createIDStringForElement(rowId) + '_circle';

			d3.select('#' + circleID)
				.style('transform-origin', 'center center')
				.style('transform', 'scale(1) translateY(-50%)')
				.style('transition', 'all 0.3s ease');
		}
	};

	//This is for devMode local
	$: if ($organizationalUnitsSelected) {
		if (!$serverEnvironment) {
			//	console.log('LOCAL DevMode organizationalUnitsSelected changed:', $organizationalUnitsSelected);
			if (gridApi) {
				gridApi.showLoadingOverlay();
			//	console.log(gridApi)
				updateTable();
			} else {
				//		console.log('gridApi not yet available');
			}
		}
	}
	//This is for devMode local
	$: if ($subGroupsSelected) {
		if (!$serverEnvironment) {
			//	console.log('LOCAL DevMode subgroups changed:', $subGroupsSelected);
			if (gridApi) {
				updateTable();
			} else {
				//		console.log('gridApi not yet available');
			}
		}
	}

	// Reactive statement to log changes, is not triggert in local devmode.
	$: if ($allReportDataForFetchedGroups) {
		//console.log(($allReportDataForFetchedGroups))
		if (gridApi) {
			if (!$dataIsCurrentlyLoading) {
				//console.log('Data ready update tables');
				updateTable();
			} else {
				//console.log('Grid data is currently loading, no action');
			}
		} else {
			//console.log('gridApi not yet available');
		}
	}
	$: {
		//console.log($historicBenchmarkSlug, 'changed')
		if (gridApi) {
			// Check if the grid API is available
			if ($heatmapMode) {
				// Refresh cells to apply the heatmap color
				console.log('heatmapMode on');
				gridApi.redrawRows();
			} else {
				gridApi.redrawRows();
			}

			// if($currentLanguage){
			// 	let updatedColumnDefs= generateColumnDefs($organizationalUnitsSelected, $subGroupsSelected);
			// 	gridApi.setColumnDefs(updatedColumnDefs);
			// 	gridApi.refreshHeader();
			// }

			if ($historicBenchmarkMode || $historicBenchmarkSlug) {
				//	d3.selectAll(".customBadgeHeatmap").remove();
				gridApi.redrawRows();
			} else {
				//	console.log('show badges');
				gridApi.redrawRows();
			}
			//console.log($historicBenchmarkSlug)
		} else {
			//console.log('Stopped: Grid API not available');
		}
	}
	$: if ($currentLanguage) {
	
			if (gridApi) {
				updateTable();
			} else {
				//		console.log('gridApi not yet available');
			}
		
	}

	async function updateTable() {
		//console.log('allReportDataForFetchedGroups updated:', $allReportDataForFetchedGroups);
		const reportKeys = Object.keys($allReportDataForFetchedGroups);
		//	console.log('reportKeys:', reportKeys);

		let reportToBaseTheDetailsOn = $dashboardFocusReportSlug;
		let detailsForTable;
		let dataForTable;

		// Extracting and logging last key's details
		if (reportKeys.length > 0) {
			const reportToFocusOn = reportToBaseTheDetailsOn;
			if ($allReportDataForFetchedGroups[reportToFocusOn]['details']['constructs']) {
				detailsForTable = $allReportDataForFetchedGroups[reportToFocusOn]['details']['constructs'];
			} else {
				console.error('CRITICAL: No details for this report');
			}

			if ($allReportDataForFetchedGroups[reportToFocusOn]) {
				dataForTable = $allReportDataForFetchedGroups[reportToFocusOn];
			} else {
				console.error('CRITICAL: No data for this report');
			}
		}
		//console.log('Details to base the table on:', detailsForTable);
		//console.log('Data to enter into the table:', dataForTable);

		let currentGroupsIndata = Object.keys(dataForTable);
		//delete details in currentGroupsIndata
		if (currentGroupsIndata.indexOf('details') != -1) {
			currentGroupsIndata.splice(currentGroupsIndata.indexOf('details'), 1);
		}

		//check if 'group_summary' is present in currentGroupsIndata, if so remove it
		if (currentGroupsIndata.indexOf('group_summary') != -1) {
			currentGroupsIndata.splice(currentGroupsIndata.indexOf('group_summary'), 1);
		}
		//console.log('currentGroupsIndata no details, no group_summary:', currentGroupsIndata)

		let currentGroups = [];
		let currentSubgroups = [];
		currentGroupsIndata.forEach((element) => {
			//if element does not contain _AND_ and is not present in currentGroups, add it to currentGroups
			if (element.indexOf('_AND_') == -1 && currentGroups.indexOf(element) == -1) {
				currentGroups.push(element);
			}
			//if element contains _AND_ split it and 2nd argument is not present in currentSubgroups, add it to currentSubgroups
			if (
				element.indexOf('_AND_') != -1 &&
				currentSubgroups.indexOf(element.split('_AND_')[1]) == -1
			) {
				currentSubgroups.push(element.split('_AND_')[1]);
			}
		});
		//console.log('currentGroups:', currentGroups);
		//console.log('currentSubgroups:', currentSubgroups);

		//now $organizationalUnitsSelected and $subGroupsSelected
		let arrayWithGroupKeys = [];
		let keysGroups = Object.keys($organizationalUnitsSelected);
		keysGroups.forEach((key) => {
			$organizationalUnitsSelected[key].forEach((group) => {
				arrayWithGroupKeys.push(group.value);
			});
		});

		let arrayWithSubGroupKeys = [];
		let keysSubgroups = Object.keys($subGroupsSelected);
		keysSubgroups.forEach((key) => {
			$subGroupsSelected[key].forEach((subgoup) => {
				arrayWithSubGroupKeys.push(subgoup.value);
			});
		});
		//console.log('arrayWithGroupKeys:', arrayWithGroupKeys);
		//console.log('arrayWithSubGroupKeys:', arrayWithSubGroupKeys);

		//compare currentGroups with arrayWithGroupKeys and currentSubgroups with arrayWithSubGroupKeys
		let arrayWithGroupsNotPresentInCurrentGroups = arrayWithGroupKeys.filter(
			(x) => !currentGroups.includes(x)
		);
		let arrayWithSubgroupsNotPresentInCurrentSubgroups = arrayWithSubGroupKeys.filter(
			(x) => !currentSubgroups.includes(x)
		);

		// if (arrayWithGroupsNotPresentInCurrentGroups.length > 0) {
		// 	console.warn(
		// 		'WARNING: Groups not present in currentGroups:',
		// 		arrayWithGroupsNotPresentInCurrentGroups
		// 	);
		// }
		// if (arrayWithSubgroupsNotPresentInCurrentSubgroups.length > 0) {
		// 	console.warn(
		// 		'WARNING: Subgroups not present in currentSubgroups:',
		// 		arrayWithSubgroupsNotPresentInCurrentSubgroups
		// 	);
		// }

		//detailsForTable={"topic1": {orderBy:4}, "topic2": {orderBy:2} } // sort on orderBy
		// Convert the object into an array of [key, value] pairs
		//console.log("Get data for table:")
		//console.log(reportToBaseTheDetailsOn, arrayWithGroupKeys, arrayWithSubGroupKeys, [focus])

		let newGridContent = await generateGridContent(
			reportToBaseTheDetailsOn,
			arrayWithGroupKeys,
			arrayWithSubGroupKeys,
			[focus]
		);
		let columnDefs = newGridContent[0];
		let rowData = newGridContent[1];

		//console.log('Colums generated:', columnDefs);
		//console.log('Rows generated:', rowData);

		gridOptions.api.setColumnDefs(columnDefs);
		gridOptions.api.setRowData(rowData);
	}

	function openModal(row, col) {
		//console.log('openModal', row, col);
		modalDetailsVisible.set({
			visible: true,
			question: row,
			team: col
		});
	}

	// Shared function to process cell values
function processCellValue(params) {
    const value = params.value;
    if (typeof value === 'number') {
        return parseFloat(value.toFixed(1));
    } else {
        return value;
    }
}

// Function to get columns to export, excluding 'Visual'
function getColumnsToExport(gridApi) {
    const columnDefs = gridApi.getColumnDefs();
    return columnDefs
        .filter(colDef => colDef.field !== 'Visual')
        .map(colDef => colDef.field);
}


// Export to Excel
function export_excel() {
    //console.log('EXCEL export function');
	//console.log($allReportDataForFetchedGroups)
	//console.log(focus)
	let reportKeys = Object.keys($allReportDataForFetchedGroups);
	reportKeys.sort();
	console.log(reportKeys);
	//check if there is a 'details' key for each of the reportKeys and then check if there is a 'context' key in the details and then a 'company' key in the context, and then a name key in the company
	let companyName = 'export';
	let currentMeasurementPeriod = 'data';
	let contentName=focus;
	reportKeys.forEach((reportKey) => {
    // Safely access nested properties with optional chaining
    companyName = $allReportDataForFetchedGroups[reportKey]?.details?.context?.company?.name ?? companyName; // Keep the current companyName if the new one is undefined
    contentName = $allReportDataForFetchedGroups[reportKey]?.details?.constructs[focus]?.name[$currentLanguage.code] ?? contentName;
	currentMeasurementPeriod = $allReportDataForFetchedGroups[reportKey]?.details?.context?.measurement?.period?.value ?? currentMeasurementPeriod; // Keep the current currentMeasurementPeriod if the new one is undefined
	});

	 let fileNameForExport=companyName+' - '+contentName+" - "+currentMeasurementPeriod+'.xlsx';



    const columnsToExport = getColumnsToExport(gridApi);
    gridApi.exportDataAsExcel({
        columnKeys: columnsToExport,
        processCellCallback: processCellValue,
		fileName: fileNameForExport,
		sheetName: 'Export'
    });
}

// Export to CSV
function export_csv() {
    //console.log('CSV');
	let reportKeys = Object.keys($allReportDataForFetchedGroups);
	reportKeys.sort();
	//console.log(reportKeys);
	//check if there is a 'details' key for each of the reportKeys and then check if there is a 'context' key in the details and then a 'company' key in the context, and then a name key in the company
	let companyName = 'export';
	let contentName=focus;
	let currentMeasurementPeriod = 'data';
	reportKeys.forEach((reportKey) => {
    // Safely access nested properties with optional chaining
    companyName = $allReportDataForFetchedGroups[reportKey]?.details?.context?.company?.name ?? companyName; // Keep the current companyName if the new one is undefined
	contentName = $allReportDataForFetchedGroups[reportKey]?.details?.constructs[focus]?.name[$currentLanguage.code] ?? contentName;
	currentMeasurementPeriod = $allReportDataForFetchedGroups[reportKey]?.details?.context?.measurement?.period?.value ?? currentMeasurementPeriod; // Keep the current currentMeasurementPeriod if the new one is undefined
	});

	let fileNameForExport=companyName+' - '+contentName+" - "+currentMeasurementPeriod+'.csv';

    const columnsToExport = getColumnsToExport(gridApi);
    gridApi.exportDataAsCsv({
        columnKeys: columnsToExport,
        processCellCallback: processCellValue,
		fileName: fileNameForExport,
    });
}

function fitTableToView() {
	//console.log('fitTableToView');
	gridOptions.api.sizeColumnsToFit({
		defaultMinWidth: 150,
		columnLimits: [{ key: 'Visual', minWidth: 200 }]
	});
}
function fitContentToView() {
	gridColumnApi.autoSizeAllColumns();
	// Specify column width limits
	const columnLimits = [
		{ field: 'Visual', minWidth: 200 }
		//... add more columns with their limits here as necessary
	];

	// Get all columns and apply width limits where necessary
	gridColumnApi.getColumns().forEach((column) => {
		const colId = column.getColId();
		const currentWidth = column.getActualWidth();

		// Check if this column has a specific limit
		const limit = columnLimits.find((limit) => limit.field === colId);
		if (limit && currentWidth < limit.minWidth) {
			// It's below the minimum width, so let's update it
			gridColumnApi.setColumnWidth(colId, limit.minWidth);
		}
	});
		
	}
</script>

<div class="container-fluid p-0">
	<!-- <div id="legendHeader">

		<div class="btn-group mb-0" role="group" aria-label="Button group" id="exportButtonGroup">
			<button class="btn btn-outline-secondary" on:click={fitTableToView}
				>{allTranslations['resultatenTab']['tableButtons']['screenFit'][$currentLanguage.code]}</button
			>
			<button class=" ml-2 btn btn-outline-secondary" on:click={fitContentToView}
				>{allTranslations['resultatenTab']['tableButtons']['contentFit'][$currentLanguage.code]}</button
			>
			<i class="fa-sharp fa-solid fa-file-csv fa-2x exportIcon" on:click={export_csv} />
			<i
				class="fa-sharp fa-solid fa-file-excel fa-2x exportButtonGroup_icon exportIcon"
				on:click={export_excel}
			/>
			<i class="fa-sharp fa-solid fa-print fa-1x exportButtonGroup_icon exportIcon" on:click={export_print}></i>
		</div>
	</div> -->

	<div id={tableID} class="ag-theme-alpine myGrid" />
</div>

<style>
	#loadingMessage {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	#legendHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.myGrid {
		width: 100%;
		height: 500px; /* minimum height you deem appropriate */
		overflow: auto; /* Important: allows scrollbar appearance */
	}
	.hidden {
		background-color: 'red' !important;
	}

	.ag-theme-alpine {
		--ag-borders: none;
		--ag-row-border-width: 1px;
		--ag-row-border-color: #dee6ed;
		--ag-header-height: 30px;
		--ag-header-background-color: transparent;
		--ag-header-foreground-color: #6c757d;
		font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
			sans-serif;
		--ag-odd-row-background-color: transparent;
		--ag-header-column-resize-handle-color: #262e62;
	}

	.ag-cell {
		color: aqua;
	}

	.circle {
		transition: all 0.3s ease; /* Adjust the duration as required */
	}

	.grow {
		/* Adjust the transform scale as required */
		transform: scale(1.5);
	}

	.btn-group {
		display: flex;
		justify-content: flex-end; /* Align to the right */
		gap: 10px; /* Adds space between the icons */
	}

	.btn-group i {
		cursor: pointer; /* Changes the mouse cursor on hover */
		transition: color 0.3s; /* Smooth color transition */
	}

	.btn-group i:hover {
		color: red; /* Color on hover */
	}

	:global(.badge-soft-nochange) {
		background-color: rgba(128, 128, 128, 0.175); /* Light gray with opacity */
		color: #808080; /* Gray */
	}
</style>
