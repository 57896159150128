<script lang="ts">
    import { menu } from "$lib/components/pages/HelpPage/HelpMenu/HelpMenu";
    import HelpMenuItem from "$lib/components/pages/HelpPage/HelpMenu/HelpMenuItem.svelte";
</script>

<div class="card help-card-menu">
    <div class="help-card-inner">
        <ul>
            {#each menu as item}
                <HelpMenuItem {...item} on:select-menu-item></HelpMenuItem>
            {/each}
        </ul>
    </div>
</div>