<script lang="ts">
    import TableControls from "$lib/components/pages/components/TableControls/TableControls.svelte";
    import Table from "$lib/components/pages/components/Table/Table.svelte";

    let activeTab: string = '';
  
    function onSwitchTabEvent(event) {
       activeTab = event.detail.tabs.find((t) => t.id === event.detail.activeTabId).keyInDataObject;
    }
</script>

<main class="content">
    <div class="container-fluid container-results">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <TableControls {activeTab} ></TableControls>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <Table on:switchTabEvent={onSwitchTabEvent}></Table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>