<script lang="ts">
	import { refreshNavBarTabStatesOnSwitch } from '$lib/utilities/uiHelperFunctions.js';
	import {
		allReportDataForFetchedGroups,
		dashboardFocusReportSlug,
		mainNavTabSelected
	} from '../../stores/dataStore';
	import Lang from '../../utilities/Lang.svelte';

	
	function toggleActiveTab(tab: string) {
		mainNavTabSelected.set(tab);

		refreshNavBarTabStatesOnSwitch(tab);
	}

	let organizationName = 'Loading data'; // default value while the data is fetched

	$: {
		// Use optional chaining to safely access nested properties
		// This will prevent runtime errors if any intermediate property is undefined or null
		const reportDetails = $allReportDataForFetchedGroups?.[$dashboardFocusReportSlug]?.details;
		// Check if reportDetails is defined and has the 'context' and 'company' properties
		if (reportDetails?.context?.company) {
			organizationName = reportDetails.context.company.name;
		}
	}
</script>

<main class="content">
	<div class="container-fluid p-0">
		<div class="col-md-10 mx-auto text-left container-usp">
			<div class="col-md-6 mb-5 lead-container">
				<h2 id="tab1_organizationName" class="h1 title-custom">{organizationName}</h2>
				<p class="title-lead fs-lg"><Lang p="homeTab" w="box1Titel" /></p>
			</div>
			<div class="row text-start">
				<hr class="content-separator" />
				<div class="col-12 col-md-6">
					<div class="d-flex align-items-center py-3 block block-left">
						<i class="fa-solid fa-chart-line top-left-icon" />
						<i class="fa-solid fa-chart-line top-right-icon" />
						<i class="fa-solid fa-chart-line bottom-right-icon" />
						<i class="fa-solid fa-chart-line large-icon" />
						<h4><Lang p="homeTab" w="box3Titel" /></h4>
						<hr />
						<a href="#" class="block-button" on:click|preventDefault={() => toggleActiveTab('tab2')}
							><Lang p="homeTab" w="box3Button" /></a
						>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="d-flex align-items-center py-3 block">
						<i class="fa-solid fa-reply top-left-icon" />
						<i class="fa-solid fa-reply top-right-icon" />
						<i class="fa-solid fa-reply bottom-right-icon" />
						<i class="fa-solid fa-reply large-icon" />
						<h4><Lang p="homeTab" w="box4Titel" /></h4>
						<hr />
						<a href="#" class="block-button" on:click|preventDefault={() => toggleActiveTab('tab3')}
							><Lang p="homeTab" w="box4Button" /></a
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>

<style></style>
