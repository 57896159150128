import { Result } from './result.js';
import { diFetchPaginated } from './difetch.js';

// Models

export type ReportSlug = string;

export interface Report {
	slug: ReportSlug;
	url: string;
	name: string;
	report_data: string;
	report_details: string;
	clusters: string[];
	start_date: Date | null;
	end_date: Date | null;
	active: boolean;
	updated: string;
	created: Date;
}

// Functions

export async function getReportList(): Promise<Result<Report[]>> {
	const url = '/api/v1/reports/';
	return diFetchPaginated(url);
}

export async function getReports(): Promise<Result<Report[]>> {
	const url = '/api/v1/reports/';
	return diFetchPaginated(url);
}
