<script lang="ts">
	import { clickOutside } from 'svelte-use-click-outside';
	import { writable, type Writable } from 'svelte/store';
	import CrossIcon from '$lib/components/pages/components/common/Icons/CrossIcon.svelte';
	import SvelteChevron from '$lib/components/pages/components/common/Icons/SvelteChevron.svelte';
	import { allTranslations, currentLanguage } from '$lib/stores/languageStore.js';

	import {
	organizationalUnitsSelected,
		subGroupsSelected,
		subgroups
	} from '$lib/stores/dataStore';
	import type { SubgroupsObject } from '../common/Group';
	import type { OptionDisplayItem } from '../common/DisplayItem';
	import type { _ } from 'ag-grid-community';
	import { afterUpdate } from 'svelte';
	import { element } from 'svelte/internal';

	let hasGroupSelected: boolean = false;

	$: selectedItems.update((values: OptionDisplayItem[]) => Object.keys($subGroupsSelected).flatMap((k) => $subGroupsSelected[k]));

	$: hasGroupSelected = Object.keys($organizationalUnitsSelected).flatMap((k) => $organizationalUnitsSelected[k]).length > 0;

	enum MenuState {
		CLOSED = 'closed',
		OPEN = 'open'
	}

	afterUpdate(() => {
		//console.log('afterupdate');
		$selectedItems.forEach(option => {
			const _htmlOption = findHtmlOption(option);

			if (_htmlOption) {
				_htmlOption.setAttribute('disabled', '');
			}
		});
	});

	const menuState: Writable<MenuState> = writable(MenuState.CLOSED);

	const menu = {
		toggleVisiblity: () => {
			if ($menuState === MenuState.OPEN) {
				$menuState = MenuState.CLOSED;
				return;
			}

			if ($menuState === MenuState.CLOSED) {
				$menuState = MenuState.OPEN;
				return;
			}
		},
		open: () => {
			$menuState = MenuState.OPEN;
		},
		close: () => {
			$menuState = MenuState.CLOSED;
		}
	};

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			menu.close();
		}
	};

	const handleClickOutside = () => {
		menu.close();
	};

	/* Store selected items */

	const enableAllButtonsOfSubgroupType = (subgroupType: string) => {
		const buttonsOfSubgroupType = document.querySelectorAll(
			`ul.subgroup-${subgroupType} li button`
		);
		buttonsOfSubgroupType.forEach((button) => {
			button.removeAttribute('disabled');
		});
	};

	const toggleDisabled = (element: HTMLElement, disabled: boolean) => {
		if(disabled) {
			element.setAttribute('disabled', '');
		} else {
			element.removeAttribute('disabled');
		}
	}

	const findHtmlOption = (option: OptionDisplayItem) => {
		const disabledButtonQuery = `ul.subgroup-${option.parent} button[data-value=${option.value.replace('.', '_')}]`;
		return document.querySelector(disabledButtonQuery) as HTMLElement;
	}

	const selectOption = (event: Event) => {
		const _button: HTMLElement = event.target as HTMLElement;
		toggleDisabled(_button, true);

		let _option: OptionDisplayItem = JSON.parse(_button.getAttribute('data-option') as string);

		subGroupsSelected.update((data) => {
			data[_option.parent].push(_option);
			return data;
		});
	};

	const selectedItems: Writable<OptionDisplayItem[]> = writable([]);

	const selectedItemBoxVisibility: Writable<string> = writable('hidden');

	const clearSelectedItem = (option: OptionDisplayItem) => {
		subGroupsSelected.update((data) => {
			data[option.parent] = data[option.parent].filter((o: OptionDisplayItem) => o.value !== option.value);
			return data;
		});	

		const currentlyDisabledButton = findHtmlOption(option);
		if (currentlyDisabledButton) {
			toggleDisabled(currentlyDisabledButton, false);
		}
	};

	const clearAllSelectedItems = () => {
		subGroupsSelected.update((data) => {
			Object.keys(data).forEach((k) => data[k] = []);
			return data;
		});
		Object.keys($subgroups).forEach((k) => enableAllButtonsOfSubgroupType(k));
		selectedItemBoxVisibility.set('hidden');
	};

	const toggleSelectedItemVisibility = () => {
		$selectedItemBoxVisibility = $selectedItemBoxVisibility === 'hidden' ? 'visible' : 'hidden';
	};
	
</script>

<svelte:window on:keydown={handleKeyDown} />

<div class="subgroup-selector">
	<ul use:clickOutside={handleClickOutside}>
		<li>
			{#if hasGroupSelected}
				<button id="open-menu" on:click={menu.toggleVisiblity}>
					{allTranslations['subgroups']['select.placeholder'][$currentLanguage.code]}
					<SvelteChevron />
				</button>
			{:else}
				<button id="menu-disabled" disabled>
					{allTranslations['subgroups']['select.group.first'][$currentLanguage.code]}
					<SvelteChevron />
				</button>
			{/if}
			<ul
				class="first-level"
				class:collapsed={$menuState === MenuState.CLOSED}
				class:expanded={$menuState === MenuState.OPEN}
			>
				{#each Object.keys($subgroups) as subgroup}
					<li>
						{$subgroups[subgroup]['displayName'][$currentLanguage.code]}
						<i class="fa-sharp fa-solid fa-chevron-right" />
						<ul class="second-level subgroup-{subgroup}">
							{#each $subgroups[subgroup].options as option}
								<li>
									<button data-option={JSON.stringify(option)} data-value={option.value.replace('.', '_')} on:click={selectOption}>
										{option.originalLabel[$currentLanguage.code]}
									</button>
								</li>
							{/each}
						</ul>
					</li>
				{/each}
			</ul>
		</li>
	</ul>
</div>
<div class="selected-items-controls overflow-height-{$selectedItemBoxVisibility}">
	<div class="selected-items-container">
		{#each $selectedItems as item}
			<button class={`item item-${item.className}`} on:click={clearSelectedItem(item)}>
				{item.originalLabel[$currentLanguage.code]}
				<CrossIcon />
			</button>
		{/each}
	</div>
	<div class="selected-items-toggle-visibility">
		{#if $selectedItems.length > 0}
			<button class="clear-selected-items" on:click={clearAllSelectedItems}>
				<CrossIcon />
			</button>
		{/if}
		<button on:click={toggleSelectedItemVisibility}>
			{#if $selectedItemBoxVisibility === 'hidden'}
				<i class="fa-sharp fa-solid fa-chevrons-down" />
			{:else}
				<i class="fa-sharp fa-solid fa-chevrons-up" />
			{/if}
		</button>
	</div>
</div>
