import { Result } from './result.js';
import { diFetchPaginated, diFetchItem } from './difetch.js';

// Models

export interface User {
	url: string;
	hashid: string;
	username: string;
	email: string;
}

export interface UserProfile {
	url: string;
	user: User;
	name: string;
}

export interface UserMagicLinks {
	hashid: string;
	username: string;
	'user-link': string;
	'management-link': string;
}

// Functions

export async function getMe(): Promise<Result<User>> {
	const url = '/api/v1/users/me/';
	return diFetchItem(url);
}

export async function getUserProfile(): Promise<Result<UserProfile>> {
	const url = '/api/v1/user_profiles/me/';
	return diFetchItem(url);
}

export async function getUsers(): Promise<Result<User[]>> {
	const url = '/api/v1/users/';
	return diFetchPaginated(url);
}

export async function getUserMagicLinks(): Promise<Result<UserMagicLinks>> {
	const url = '/api/sessionauth/login-link/';
	return diFetchItem(url);
}
