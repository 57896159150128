<script lang="ts">
	import { onMount } from 'svelte';
	import Lang from './utilities/Lang.svelte';
	import {
		loggedIn,
		mainNavTabSelected,
		userProfile,
		intitialDataFetched,
		dashboardFocusReportSlug,
	} from './stores/dataStore';
	import {
		currentLanguage,
		availableLanguagesInDashboardThatCanBeSelected,
		allTranslations,
	
	} from './stores/languageStore.js';
	import * as auth from '$lib/diapi/diauthscorius.js';
	import { refreshNavBarTabStatesOnSwitch } from '$lib/utilities/uiHelperFunctions.js';
	import { getDisplayReportName } from './utilities/formatDataHelperFunctions';

	const setLanguage = (event) => {
		const code = event.currentTarget.dataset.code;
		const language = $availableLanguagesInDashboardThatCanBeSelected.find(
			(lang) => lang.code === code
		);
		currentLanguage.set({
			flag: language.flag,
			name: language.name,
			code: code
		});
	};

	function toggleActiveTab(tab: string) {
		mainNavTabSelected.set(tab);
		refreshNavBarTabStatesOnSwitch(tab);
	}

	function logout() {
		auth.logout();

		sessionStorage.clear();
		localStorage.clear();
		const cookies = document.cookie.split(';');

		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
		}
		//now retun to home
		loggedIn.set(false);
	}

</script>
<nav id="dashboard-nav" class:not-logged-in={!$loggedIn}>
	{#if $loggedIn}
		<ul>
			<li id="navbar_tab1" class="active">
				<a href="#" on:click|preventDefault={() => toggleActiveTab('tab1')}>
					<i class="fa-solid fa-house" /> <span class="text"> <Lang p="nav" w="tab1" /></span>
				</a>
			</li>

			<li id="navbar_tab2" class="navbarTab {!$intitialDataFetched ? 'disabled' : ''}">
				<a href="#" on:click|preventDefault={() => toggleActiveTab('tab2')}>
					<i class="fa-sharp fa-chart-line" /> <span class="text"><Lang p="nav" w="tab2" /></span>
				</a>
			</li>

			<li id="navbar_tab3" class="{!$intitialDataFetched ? 'disabled' : ''}">
				<a href="#" on:click|preventDefault={() => toggleActiveTab('tab3')}>
					<i class="fa-solid fa-comment" /> <span class="text"><Lang p="nav" w="tab3" /></span>
				</a>
			</li>

			<li id="navbar_tab4">
				<a href="#" on:click|preventDefault={() => toggleActiveTab('tab4')}>
					<i class="fa-solid fa-circle-info" />
					<span class="text"><Lang p="nav" w="tab4" /></span>
				</a>
			</li>
		</ul>
	{/if}
	
	{#if $dashboardFocusReportSlug && $mainNavTabSelected !== 'tab1' && $mainNavTabSelected !== 'tab4'}
		<div class="dashboard-report-slug"> 
			<p>
				{allTranslations['resultatenTab']['legend']['explanation1'][$currentLanguage.code]} {getDisplayReportName($dashboardFocusReportSlug)}
			</p>
		</div>
	{/if}

	<ul class="navbar-nav mixin-reset-list navbar-align">
		{#if $loggedIn}
			<li>
				<div class="dropdown-left">
					<button data-bs-toggle="dropdown" aria-expanded="false">
						{ $userProfile.user.email ? $userProfile.user.email : $userProfile.user.username }
					</button>
					<ul class="dropdown-menu">
						<li>
							<a href="index.html" class="dropdown-item" on:click|preventDefault={logout}>
								<Lang p="nav" w="logout" />
							</a>
						</li>
					</ul>
				</div>
			</li>
		{/if}
		<li id="language-menu">
			<div class="dropdown-left">
				<button id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
					<img src={$currentLanguage.flag} alt={$currentLanguage.name} />
				</button>
				<ul class="dropdown-menu" aria-labelledby="languageDropdown">
					{#each $availableLanguagesInDashboardThatCanBeSelected as language}
						<li>
							<a href="#" data-code={language.code} on:click|preventDefault={setLanguage}>
								<img src={language.flag} alt={language.name} />
								<span>{language.name}</span>
							</a>
						</li>
					{/each}
				</ul>
			</div>
		</li>
	</ul>

</nav>

<style>
	#usernameNavbar {
		color: #444;
		font-size: 14px;
	}

	.nav-flag {
		@apply mt-2;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		display: block;
		font-size: 1.5rem;
		color: #6c757d;
		transition: background 1s ease-in-out, color 1s ease-in-out;
		line-height: 1.4;
	}

	.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
</style>
