<script lang="ts">
	import GroupSelector from '$lib/components/pages/components/TableControls/GroupSelector/GroupSelector.svelte';
	import SubgroupSelector from '$lib/components/pages/components/TableControls/SubgroupSelector/SubgroupSelector.svelte';
	import TableSettings from '$lib/components/pages/components/TableControls/TableSettings/TableSettings.svelte';
	import { dashboardFocusReportSlug, mainNavTabSelected, dashboardFocusReportSlugForBenchmark } from '$lib/stores/dataStore';
	import { allTranslations, currentLanguage } from '$lib/stores/languageStore';
	import { createEventDispatcher } from 'svelte';

	export let activeTab: string = '';

	const _dispatch = createEventDispatcher();
  
  	function forward(event: CustomEvent) {
    	_dispatch('toggleLineBarsEvent', event.detail);
  	}

	let hideTableSettings: boolean = false;
	$: hideTableSettings = $mainNavTabSelected === 'tab2' && activeTab === 'results' && $dashboardFocusReportSlug === $dashboardFocusReportSlugForBenchmark;

</script>

<div class="row">
	<div class="col-12 col-lg-6 col-xl-6 col-groupSelector">
		<GroupSelector/>
	</div>
	<div class="col-12 col-lg-6 col-xl-4 col-subgroupSelector">
		<p class="title">{allTranslations['subgroups']['title'][$currentLanguage.code]}</p>
		<SubgroupSelector />
	</div>
	{#if !hideTableSettings}
		<div class="col-12 col-lg-4 col-xl-2">
			<p class="title">{allTranslations['table.settings']['title'][$currentLanguage.code]}</p>
			<TableSettings {activeTab} on:toggleLineBarsEvent={forward} />
		</div>
	{/if}
</div>
