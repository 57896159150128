<script lang="ts">
	import Navbar from '$lib/Navbar.svelte';
	import Footer from '$lib/Footer.svelte';
	import Dashboard from '$lib/DashboardTab.svelte';
	import Logintab from '$lib/LoginTab.svelte';
	import { loggedIn } from '$lib/stores/dataStore.ts';
	import { LicenseManager } from 'ag-grid-enterprise';
	LicenseManager.setLicenseKey(
		'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052628}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Data_Ink_BV}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Scorius_Dashboard}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Scorius_Dashboard}_need_to_be_licensed___{Scorius_Dashboard}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{27_December_2024}____[v3]_[0102]_MTczNTI1NzYwMDAwMA==da2cf127f49ace050f8e0ece80d43644Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052628}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Data_Ink_BV}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Scorius_Dashboard}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Scorius_Dashboard}_need_to_be_licensed___{Scorius_Dashboard}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{27_December_2024}____[v3]_[0102]_MTczNTI1NzYwMDAwMA==da2cf127f49ace050f8e0ece80d43644'
	);

	let loggedInValue: boolean;
	loggedIn.subscribe((value) => {
		loggedInValue = value;
	});
</script>

<div id="body-container">
	{#if loggedInValue}
		<Navbar />
		<Dashboard />
		<Footer />
	{:else}
		<Logintab />
	{/if}
</div>

<style>
	:global(.hidden) {
		display: none;
	}

	:root {
		--component-color: #6c757d;
		--component-inactive: #e0e0e0;
		--line-border-empty: #e0e0e0;
		--line-border-white: white;
		--line-border-active: #3f80ea;
		--data-color: #1f9bcf;
		--benchmark-color: #cf1f9b;
		--data-color-positive: #4bbf73;
		--data-color-negative: #d9534f;
		--icon-color-heatmap: #e0eafc;
	}
</style>
