// Result type, based on: https://morioh.com/p/d23044ebd43f

/*
 * Dict type... to make it easier to define generic objects (dictionaries)
 */
export interface Dict<T> {
	[Key: string]: T;
}

/*
 * Option type... so we do not need to worry about empty return values
 */
export type Option<T> = { ok: true; value: T } | { ok: false; value: undefined };

/*
 * Option type constructor for a Value result
 */
export const Value = <T>(data: T): Option<T> => {
	return { ok: true, value: data };
};

/*
 * Option type constructor for a None result (with empty value)
 */
export const None = <_>(): Option<never> => {
	return { ok: false, value: undefined };
};

/*
 * Result type... so we do not need to worry about throwing errors
 */
export type Result<T, E = undefined> = { ok: true; value: T } | { ok: false; error: E | undefined };

/*
 * Result type constructor for an Ok result with value
 */
export const Ok = <T>(data: T): Result<T, never> => {
	return { ok: true, value: data };
};

/*
 * Result type constructor for an Error result with error
 */
export const Err = <E>(error?: E): Result<never, E> => {
	return { ok: false, error };
};
