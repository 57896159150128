export function refreshNavBarTabStatesOnSwitch(currentTab: string) {
	// Select all navbar tabs
	const navbarTabs = document.querySelectorAll('nav#dashboard-nav > ul:first-child li');

	// Remove 'active' class from all tabs
	navbarTabs.forEach((element) => {
		element.classList.remove('active');
	});

	// Add 'active' class to the selected tab
	const activeTab = document.getElementById(`navbar_${currentTab}`);
	if (activeTab) {
		activeTab.classList.add('active');
	}
}
