import { Dict, Option, Value, None, Result, Ok, Err } from './result.js';
import { absoluteUrl } from './utils.js';

/**
 * Return cookie content
 *
 * @param {string} name - Key of the item inside the cookie
 * @return Optional string value of the requested key in the cookie
 */
function getCookie(name: string): Option<string> {
	if (document.cookie && document.cookie !== '') {
		let cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			let cookie = cookies[i].trim();
			if (cookie.substring(0, name.length + 1) === name + '=') {
				const cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				return Value(cookieValue);
			}
		}
	}
	return None();
}

/**
 * Type definition for the HTTP methods GET / POST / PUT / UPDATE
 */
export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'UPDATE';

/**
 * Get or Post data from the DI API
 *
 * @param {string} url - Url to access
 * @param {HTTPMethod} method - HTTP method (GET / POST / PUT / UPDATE)
 * @param {any} data - data object (must be JSON serializable)
 * @return Promissed response
 */
export async function diFetch(url: string, method: HTTPMethod, data: any): Promise<Response> {
	console.log(`--- START diFetch url ${url}`);
	// Build the message body
	let body = null;
	let headers: Dict<string> = {};
	if (data !== null) {
		body = JSON.stringify(data);
		headers['Content-type'] = 'application/json; charset=UTF-8';
	}
	// This CSRF implementation is Django specific
	const absUrl = absoluteUrl(url);
	const absUrlHost = absUrl.split('://').slice(-1)[0];
	if (url.startsWith('/') || absUrlHost.startsWith(window.location.hostname)) {
		// Only send CSRF token to own domain (or we have a token leakage)
		const csrf_token = getCookie('csrftoken');
		if (csrf_token.ok === true) {
			headers['X-CSRFToken'] = csrf_token.value;
		}
	}
	// Execute the interaction
	return fetch(absUrl, {
		method: method,
		body: body,
		headers: headers
	}).then((result) => {
		console.log(`--- FINISH diFetch url ${url}`, result);
		return result;
	});
}

/**
 * Post new or update an existing User Priority
 *
 * @param {string} url - Url to access
 * @return Promissed response
 */
export async function diFetchItem<T>(url: string): Promise<Result<T>> {
	console.log('--- diFetchItem ...');
	return diFetch(url, 'GET', null)
		.then((response: Response) => {
			if (!response.ok) {
				throw new Error(`HTTP error: ${response.status}`);
			}
			return response.json() as Promise<T>;
		})
		.then((data: T) => {
			return Ok(data);
		})
		.catch((error) => {
			return Err(error);
		});
}

/**
 * Type wrapping paginated Django results
 */
interface PaginationContainer<T> {
	count: number;
	next: null | string;
	previous: null | string;
	results: T[];
}

/**
 * Post new or update an existing User Priority
 *
 * Paginated results are expected to have the Pagination type.
 *
 * @param {string} url - Url to access
 * @return Promissed response
 */
export async function diFetchPaginated<T>(url: string): Promise<Result<Array<T>>> {
	console.log('--- diFetchPaginated ...');
	return diFetch(url, 'GET', null)
		.then((response) => {
			if (!response.ok) {
				throw new Error(`HTTP error: ${response.status}`);
			}
			return response.json() as Promise<PaginationContainer<T>>;
		})
		.then((data: PaginationContainer<T>) => {
			const results = data.results as Array<T>;
			if (data.next === null) {
				// there is no next page, we are done
				return Ok(results);
			} else {
				// there is a next page
				return diFetchPaginated(data.next).then((data2) => {
					if (data2.ok !== true) {
						return Err(data2.error);
					} else {
						const collection = results.concat(data2.value as Array<T>);
						return Ok(collection);
					}
				});
			}
		})
		.catch((error) => {
			return Err(error);
		});
}
