<script lang="ts">
	import {
		allReportDataForFetchedGroups,
		dashboardFocusReportSlug,
		dataIsCurrentlyLoading,
		historicBenchmarkMode,
		historicBenchmarkSlug,
		historicBenchmarksAvailable,
		modalDetailsVisible,
		organizationalUnitsSelected,
		subGroupsSelected
	} from '../../stores/dataStore.js';
	import { allTranslations, currentLanguage } from '../../stores/languageStore.js';

	import {
		getQuestionData,
		scaleOrginalValueToDesiredScale
	} from '../../utilities/formatDataHelperFunctions.js';

	let dataObjectTileResultsPerGroup = {};

	export let tilesToMakeForTheseTopics;

	$: if ($allReportDataForFetchedGroups) {
		if (!$dataIsCurrentlyLoading) {
			//console.log('Data ready update tiles');
			createTiles();
		} else {
			//console.log('Tile data is currently loading, no action');
		}
	}


	$: if ($historicBenchmarkSlug) {
		if (!$dataIsCurrentlyLoading) {
			createTiles();
		} else {
			//console.log('Tile data is currently loading, no action');
		}
	}

	function createTiles() {
		dataObjectTileResultsPerGroup = {};

		//subgroups
		let keysSubgroups = Object.keys($subGroupsSelected);
		let arrayWithSubGroupKeys = [];
		keysSubgroups.forEach((key) => {
			$subGroupsSelected[key].forEach((subgoup) => {
				arrayWithSubGroupKeys.push(subgoup);
			});
		});
		//console.log('arrayWithSubGroupKeys', arrayWithSubGroupKeys);

		//issue is that group can be selected but no data is available yet
		let currentlySelectedGroupsWithDataPresent = [];
		let keysTypeOfGroups = Object.keys($organizationalUnitsSelected);
		keysTypeOfGroups.forEach((typeOfGroupKey) => {
			$organizationalUnitsSelected[typeOfGroupKey].forEach((group) => {
				if ($allReportDataForFetchedGroups?.[$dashboardFocusReportSlug]?.[group.value])
					dataObjectTileResultsPerGroup[group.value] = {
						originalLabel: group.originalLabel,
						tileData: {}
					};
				else {
					//LET OP: hierdoor verschijnen groepen zonder data dus niet in de kaartjes
					console.warn('No data for this group:', group.value);
				}

				//add subgroups if data available
				arrayWithSubGroupKeys.forEach((subgroup) => {
					let groupKey = group.value + '_AND_' + subgroup.value;
					if ($allReportDataForFetchedGroups?.[$dashboardFocusReportSlug]?.[groupKey]) {
						let translationKeys=Object.keys(group.originalLabel)
						let newLabelObject={}
						//TODO Make robust when a translation does not exist

						translationKeys.forEach((key)=>{
							 const groupLabel = group.originalLabel[key] || '<label niet gevonden>'; // Fallback to empty string if not found
							 const subgroupLabel = subgroup.originalLabel[key] || '<label niet gevonden>'; // Fallback to empty string if not found
								
							// Only add a space if both labels are not empty
							const separator = groupLabel && subgroupLabel ? ' ' : '';
								
							newLabelObject[key] = groupLabel + separator + subgroupLabel;
						})
						dataObjectTileResultsPerGroup[groupKey] = {
							originalLabel: newLabelObject,
							tileData: {}
						};
					} else {
						console.warn('No data for this subgroup yet', subgroup);
					}
				});


			});
		});
		
		//console.log('tilesToMake', tilesToMakeForTheseTopics)
		for (let groupKey of Object.keys(dataObjectTileResultsPerGroup)) {
			if ($allReportDataForFetchedGroups?.[$dashboardFocusReportSlug]?.[groupKey]) {
				let keysOfTilesToMake = Object.keys(tilesToMakeForTheseTopics);

				keysOfTilesToMake.forEach((tileToMake) => {
					let score = null;
					let question = null;
					let change = null;

			

					let tileDataCurrentReport =
						$allReportDataForFetchedGroups[$dashboardFocusReportSlug][groupKey];
					let tileDataBenchmarkReport =
						$allReportDataForFetchedGroups[$historicBenchmarkSlug][groupKey];
					const result = findTileScores(tileDataCurrentReport, tileDataBenchmarkReport, groupKey);

					if (tileToMake === 'results.low') {
						score = result.lowestScoreObj.itemObject
							? result.lowestScoreObj.itemObject.scaledMean
							: 'n/a';
						question = result.lowestScoreObj.itemObject
							? result.lowestScoreObj.itemObject.question
							: 'n/a';
						change = result.lowestScoreObj.difference;
					} else if (tileToMake === 'results.high') {
						score = result.highestScoreObj.itemObject
							? result.highestScoreObj.itemObject.scaledMean
							: 'n/a';
						question = result.highestScoreObj.itemObject
							? result.highestScoreObj.itemObject.question
							: 'n/a';
						change = result.highestScoreObj.difference;
					} else if (tileToMake === 'results.ascender') {
						score = result.biggestAscender.itemObject
							? result.biggestAscender.itemObject.scaledMean
							: 'n/a';
						question = result.biggestAscender.itemObject
							? result.biggestAscender.itemObject.question
							: 'n/a';
						change = result.biggestAscender.difference;
					} else if (tileToMake === 'results.descender') {
						score = result.biggestDescender.itemObject
							? result.biggestDescender.itemObject.scaledMean
							: 'n/a';
						question = result.biggestDescender.itemObject
							? result.biggestDescender.itemObject.question
							: 'n/a';
						change = result.biggestDescender.difference;
					} else if (tileToMake === 'results.nes') {
						score = result.nesScoreObj.itemObject; // ? result.nesScoreObj.itemObject : 'n/a';
						change = result.nesScoreObj.difference;
					} else if (tileToMake === 'results.response') {
						score = result.responseScoreObj.itemObject ? result.responseScoreObj.itemObject : 'n/a';
						change = result.responseScoreObj.difference;
					}

					dataObjectTileResultsPerGroup[groupKey]['tileData'][tileToMake] = {
						score: roundFormatter(score),
						question: question,
						change: roundFormatter(change),
						details: tilesToMakeForTheseTopics[tileToMake]
					};
				});
				//console.log('xxxy');
				//console.log(dataObjectTileResultsPerGroup);
			} else {
				//console.warn('No data for this group yet', groupKey);
			}
		}
	}

	function findTileScores(dataCurrent, dataBenchmark, groupKey) {
		let highestScoreObj = { itemObject: null, difference: null };
		let lowestScoreObj = { itemObject: null, difference: null };
		let biggestAscender = { itemObject: null, difference: 0 };
		let biggestDescender = { itemObject: null, difference: 0 };
		let nesScoreObj = { itemObject: null, difference: 0 };
		let responseScoreObj = { itemObject: null, difference: 0 };

		for (let key in dataCurrent) {
			if (dataCurrent[key] && dataCurrent[key].responseType === 'LIKERT_7_POS') {


				//First check if the scale is also defined in details:
		
				if(getQuestionData(dataCurrent[key].question)===null || getQuestionData(dataCurrent[key].question).show===false){
					//console.log('Scale not defined in details, skipping', key)
					continue;
				}

				const unscaledMean = dataCurrent[key].mean;
				const scaledMean = scaleOrginalValueToDesiredScale(key, dataCurrent[key].mean);
				dataCurrent[key].scaledMean = scaledMean;

				const unscaledBenchmarkMean =
					dataBenchmark?.[key]?.responseType === 'LIKERT_7_POS' ? dataBenchmark[key].mean : null;

				const scaledBenchmarkMean = scaleOrginalValueToDesiredScale(key, unscaledBenchmarkMean);

				const difference = scaledBenchmarkMean !== null ? scaledMean - scaledBenchmarkMean : 'n/a';

				// Find highest and lowest in current data
				if (!highestScoreObj.itemObject || scaledMean > highestScoreObj.itemObject.scaledMean) {
						
					highestScoreObj = { itemObject: dataCurrent[key], difference };
				}
				if (!lowestScoreObj.itemObject || scaledMean < lowestScoreObj.itemObject.scaledMean) {
					lowestScoreObj = { itemObject: dataCurrent[key], difference };
				}

				// Find biggest ascender and descender
				if (scaledBenchmarkMean !== null) {
					if (difference > biggestAscender.difference) {
						biggestAscender = { itemObject: dataCurrent[key], difference };
					}
					if (difference < biggestDescender.difference) {
						biggestDescender = { itemObject: dataCurrent[key], difference };
					}
				}
			}
		}
	
		nesScoreObj = {
			itemObject: null, 
			difference: 999 
			};
			// Safely attempt to access 'results.nes' 
			nesScoreObj.itemObject = dataCurrent?.['results.nes'].score ?? null;
			// Proceed with calculations only if 'results.nes' is present in dataCurrent
			if (nesScoreObj.itemObject !== null) {
				// Safely calculate the difference if 'results.nes' is present in both objects, using optional chaining
				const currentNes = dataCurrent?.['results.nes'].score ?? null;
				const benchmarkNes = dataBenchmark?.['results.nes'].score ?? null;
				// Ensure both currentNes and benchmarkNes are not null before calculating the difference
				if (currentNes !== null && benchmarkNes !== null) {
					nesScoreObj.difference = currentNes - benchmarkNes;
				}
			}

		let responsDataCurrent =
			$allReportDataForFetchedGroups[$dashboardFocusReportSlug]?.['group_summary']?.[groupKey];
		let responsDataBenchmark =
			$allReportDataForFetchedGroups[$historicBenchmarkSlug]?.['group_summary']?.[
				groupKey
			];

		let responsCurrent = null;
		let responsBenchmark = null;

		if (responsDataCurrent && responsDataCurrent['invited'] > 0) {
			responsCurrent = (responsDataCurrent['response'] / responsDataCurrent['invited']) * 100;
		}

		if (responsDataBenchmark && responsDataBenchmark['invited'] > 0) {
			responsBenchmark = (responsDataBenchmark['response'] / responsDataBenchmark['invited']) * 100;
		}

		responseScoreObj = {
			itemObject: responsCurrent,
			difference: responsCurrent - responsBenchmark
		};

		return {
			highestScoreObj: highestScoreObj.itemObject ? highestScoreObj : 'n/a',
			lowestScoreObj: lowestScoreObj.itemObject ? lowestScoreObj : 'n/a',
			biggestAscender: biggestAscender.itemObject ? biggestAscender : 'n/a',
			biggestDescender: biggestDescender.itemObject ? biggestDescender : 'n/a',
			nesScoreObj: !isNaN(nesScoreObj.itemObject) ? nesScoreObj : 'n/a',
			responseScoreObj: responseScoreObj.itemObject ? responseScoreObj : 'n/a'
		};
	}

	function roundFormatter(params) {
		if (typeof params === 'number' && params !== 999) {
			return params.toFixed(1);
		}
		return '--'; // or return some default value or null
	}

	function roundFormatterPercentage(params) {
		if (typeof params === 'number') {
			return params.toFixed(0);
		}
		return ''; // or return some default value or null
	}

	const tileIcons =  {
		'results.low': 'gauge-low',
		'results.high': 'gauge-high',
		'results.ascender': 'sunrise',
		'results.descender': 'sunset',
		'results.response': 'comment',
		'results.nes': 'gauge',
	}

	const tileClicked = (tileKey) => {
		console.log('tile', tileKey);
		if(tileKey === 'results.nes') {
			modalDetailsVisible.set({
			visible: true,
			title: 'NES',
			question: tileKey,
			team: 'team.twb'
		});
		}
	}

</script>

{#each Object.keys(dataObjectTileResultsPerGroup) as keyOrganizationalUnit}
	<div class="row mt-3 ml-2">
		<h5 class="card-title mb-3">
			{dataObjectTileResultsPerGroup[keyOrganizationalUnit].originalLabel[$currentLanguage.code]}
		</h5>

		{#each Object.keys(dataObjectTileResultsPerGroup[keyOrganizationalUnit]['tileData']) as keyTile}
			<div class="col-12 col-sm-6 col-xxl d-flex tile-container">
				<!-- TODO convert to tile component -->
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<div class="card flex-fill" class:tile-pointer={keyTile === "results.nes"} on:click={tileClicked(keyTile)} >
					<div class="card-body py-4">
						<div class="d-flex align-items-start">
							<div class="flex-grow-1">
								
								<div class="row">
									<div class="col-sm-8 col-lg-10 col-xl-8">
										<h1 id="summaryResults">
											{dataObjectTileResultsPerGroup[keyOrganizationalUnit]['tileData'][keyTile]['details']['name'][$currentLanguage.code]}
										</h1>
									</div>

									<div class="col-sm-4 col-lg-2 col-xl-4">
										<div class="icon-container">
											<i class="fa-light fa-{tileIcons[keyTile]} fa-3x" />
										</div>
									</div>
								</div>

								
									<div class="row mt-4">
										<p class="score">
											{dataObjectTileResultsPerGroup[keyOrganizationalUnit]['tileData'][keyTile]['score']}

											{#if $historicBenchmarksAvailable && !$historicBenchmarkMode}
												<span class="badge me-2 {dataObjectTileResultsPerGroup[keyOrganizationalUnit]
													?.tileData[keyTile]?.change < 0
													? 'badge-soft-warning'
													: 'badge-soft-success'}">
													<i class="mdi mdi-arrow-bottom-right" />
													{dataObjectTileResultsPerGroup[keyOrganizationalUnit]['tileData'][keyTile]['change']}
												</span>
											{/if}
										</p>
									</div>
							

								{#if dataObjectTileResultsPerGroup[keyOrganizationalUnit]?.tileData[keyTile]?.question}
									<div class="row mt-4">
										<div class="col-12">
											<span class="question text-muted">
												{getQuestionData(dataObjectTileResultsPerGroup[keyOrganizationalUnit].tileData[keyTile].question)?.['text']?.[$currentLanguage.code] ?? allTranslations['resultatenTab']['noDataCard'][$currentLanguage.code]}
											</span>
										</div>
									</div>
								{:else if keyTile === "results.nes"}
									<div class="row mt-4">
										<div class="col-12">
											<span class="question text-muted">
												{$allReportDataForFetchedGroups?.[$dashboardFocusReportSlug]?.details?.constructs['results']['topics']['results.nes']['definition'][$currentLanguage.code]}
											</span>
										</div>
									</div>
								{/if}
								
							</div>

						</div>
					</div>
				</div>
			</div>
		{/each}
	</div>
{/each}
