<script lang="ts">
	import { onMount } from 'svelte';
	import Lang from '../../utilities/Lang.svelte';
	import {
		availableReports,
		organizationalUnitsSelected,
		subGroupsSelected,
		historicBenchmarkMode,
		historicBenchmarkSlug,
		heatmapMode,
		allReportDataForFetchedGroups,
		dashboardFocusReportSlug,
		modalDetailsVisible,
		dataIsCurrentlyLoading,
		serverEnvironment,
		standardAmountOfReportsToFetch,
		agGridLicense
	} from '../../stores/dataStore';
	import { allTranslations, currentLanguage } from '../../stores/languageStore.js';
	import {
		getGroupNameForKey,
		getDisplayReportName
	} from '../../utilities/formatDataHelperFunctions.js';

	import Grid from './reponsTabGrid.svelte';

	import {
		AgCharts,
		type AgCartesianChartOptions,
		type AgChartOptions
	} from 'ag-charts-enterprise';
	import type { AgCartesianSeriesTooltipRendererParams } from 'ag-charts-enterprise';
	import Actions from '../pages/HelpPage/HelpContent/pages/actions.interventions.en.svelte';

	AgCharts.setLicenseKey(agGridLicense);
	let chartLine;
	let chartBar;
	let chartInitialized = false;

	export let showBars: boolean = false;

	function renderer(params: AgCartesianSeriesTooltipRendererParams) {
		let content = '';

		if (params.datum) {
			// Adding the percentage value
			if (typeof params.datum[params.yKey] === 'number') {
				content += `${
					allTranslations['responseTab']['tooltipRespons'][$currentLanguage.code]
				}: ${params.datum[params.yKey].toFixed(1)}%`;
			}
			// Adding 'invited' and 'responded' information if available
			if (params.datum.invited) {
				content += `<br>${
					allTranslations['responseTab']['tooltipInvited'][$currentLanguage.code]
				}: ${params.datum.invited}`;
			}
			if (params.datum.responded) {
				content += `<br>${
					allTranslations['responseTab']['tooltipParticipated'][$currentLanguage.code]
				}: ${params.datum.responded}`;
			}
		}

		return { content: content };
	}

	let seriesLine = [
		{ type: 'line', xKey: 'year', yKey: 'responseA', connectMissingData: false },
		{ type: 'line', xKey: 'year', yKey: 'responseB', connectMissingData: false }
	];
	let seriesBar = [
		{ type: 'bar', xKey: 'year', yKey: 'responseA', connectMissingData: false },
		{ type: 'bar', xKey: 'year', yKey: 'responseB', connectMissingData: false }
	];

	const options: AgCartesianChartOptions = {
		container: '',
		contextMenu: {
			enabled: false
		},
	
		theme: {
			overrides: {
				bar: {
					series: {
						highlightStyle: {
							series: {
								dimOpacity: 0.2,
								strokeWidth: 2
							}
						}
					}
				},
				line: {
					series: {
						highlightStyle: {
							series: {
								dimOpacity: 0.2,
								strokeWidth: 4
							}
						}
					}
				}
			}
		},
		axes: [
				{
				type: "number",
				position: "left",
				label: {
					formatter: (params) => `${params.value}%`,
				},
				crosshair: {
					enabled: false,
				},
				},
				{
				type: "category",
				position: "bottom",
				crosshair: {
					enabled: false,
				},
				},
			],
		data: [],
		series: []
	};
	onMount(() => {
		let containerChartLine = document.getElementById('lineChart');
		if (containerChartLine) {
			let chartSeriesData = getChartData('line');
			options.series = chartSeriesData[0];
			options.data = chartSeriesData[1];
			options.container = containerChartLine;
			chartLine = AgCharts.create(options);
			chartInitialized = true;
		} else {
			console.error('lineChar container not found');
		}
		let containerChartBar = document.getElementById('barChart');
		if (containerChartBar) {
			let chartSeriesData = getChartData('bar');
			options.series = chartSeriesData[0];
			options.data = chartSeriesData[1];
			options.container = containerChartBar;
			chartBar = AgCharts.create(options);
			chartInitialized = true;
		} else {
			console.error('barchart container not found');
		}
	});
	
	$: {
		if ($organizationalUnitsSelected) {
			if (chartInitialized) {
				updateChartData('line');
				updateChartData('bar');
			}
		}
	}
	$: {
		if ($subGroupsSelected) {
			//console.log('Start update because there is a change in subGroupsSelected')
			if (chartInitialized) {
				updateChartData('line');
				updateChartData('bar');
			}
		}
	}

	//dit moet het oplossen, bovenstaande $subgr en $group zijn eigenlijk vooral voor local development:
	$: {
		if ($allReportDataForFetchedGroups) {
			//console.log('Start update because there is a change in data')
			if (chartInitialized) {
				updateChartData('line');
				updateChartData('bar');
			}
		}
	}



	function updateChartData(type) {
		let chartSeriesData = getChartData(type);
		
		options.series = chartSeriesData[0];
		options.data = chartSeriesData[1];
		let id = type + 'Chart';
		options.container = document.getElementById(id);
		//console.log('Updating chart options for type:', type, 'with options:', options);
		if (type === 'line') {
			
			
			AgCharts.updateDelta(chartLine,options);
			

		} else if (type === 'bar') {
		
			AgCharts.updateDelta(chartBar, options);
		}



	}

	function getChartData(type) {
		let arrayWithGroupsToShowInChart = [];
		let arrayWithGroupKeys = [];

		let keysGroups = Object.keys($organizationalUnitsSelected);
		keysGroups.forEach((key) => {
			$organizationalUnitsSelected[key].forEach((group) => {
				arrayWithGroupKeys.push(group.value);
			});
		});

		let keysSubgroups = Object.keys($subGroupsSelected);

		let arrayWithSubGroupKeys = [];
		keysSubgroups.forEach((key) => {
			$subGroupsSelected[key].forEach((subgoup) => {
				arrayWithSubGroupKeys.push(subgoup.value);
			});
		});

		arrayWithGroupKeys.forEach((group) => {
			arrayWithGroupsToShowInChart.push(group);
			//  console.log('Check if data for this subgroup is already fetched: ', subgroup);
			if (arrayWithSubGroupKeys.length > 0) {
				arrayWithSubGroupKeys.forEach((subgroup) => {
					let groupKey = group + '_AND_' + subgroup;
					arrayWithGroupsToShowInChart.push(groupKey);
				});
			}
		});

		let arrayWithSlugsToShow = [];
		// Ensure you don't attempt to fetch more reports than are available
		let numberOfReportsToFetch = Math.min($standardAmountOfReportsToFetch, $availableReports.length);

		for (let i = 0; i < numberOfReportsToFetch; i++) {
			arrayWithSlugsToShow.push($availableReports[i].slug);
		}

		arrayWithSlugsToShow.sort()

		//console.log(arrayWithGroupsToShowInChart, arrayWithSlugsToShow)

		//console.log('search this object for the group_summary response statistics', $allReportDataForFetchedGroups)
		
		let data = [];
		arrayWithGroupsToShowInChart.forEach((groupKey) => {
			arrayWithSlugsToShow.forEach((slug) => {
				let score = null;
				let invited = null;
				let responded = null;

				if ($allReportDataForFetchedGroups[slug]?.group_summary?.[groupKey]) {
					invited = $allReportDataForFetchedGroups[slug].group_summary[groupKey]['invited'];
					responded = $allReportDataForFetchedGroups[slug].group_summary[groupKey]['response'];
					score = (responded / invited) * 100;
				}
				let groupKeyForChart = groupKey.replace(/\./g, '_');

				//data.push({ 'year': slug, 'team.twb': score });
				data.push({
					report: getDisplayReportName(slug),
					[groupKeyForChart]: score,
					invited: invited,
					responded: responded
				});
			});
		});

		//console.log('collected response data', data)
		let series = [];
		arrayWithGroupsToShowInChart.forEach((groupKey) => {
			//repace . by _
			let groupKeyForChart = groupKey.replace(/\./g, '_');
			series.push({
				type: type,
				xKey: 'report',
				yKey: groupKeyForChart,
				yName: getGroupNameForKey(groupKey),
				tooltip: { renderer: renderer },
				marker: {
					enabled: true,
					size: 15
				}
				//connectMissingData: false
			});
		});

		//console.log('series', series);
		//console.log('data', data);
		return [series, data];
	}
</script>


<div class="row">
	<div class="col-12">
		<h5 class="card-title mb-4"><Lang p="responseTab" w="box1Titel" /></h5>
		<div
			id="lineChart"
			style="height: 400px; width: 100%;"
			class="mb-4"
			class:hidden={showBars}
		/>
		<div
			id="barChart"
			style="height: 400px; width: 100%;"
			class:hidden={!showBars}
		/>
	</div>
	<div class="col-12">
		<div class="card w-100" id="responseDataCard">
			<div class="card-header">
				<h5 class="card-title mb-0"><Lang p="responseTab" w="box2Titel" /></h5>
				<Grid focus="respons" tableID="responseTable" />
			</div>
		</div>
	</div>
</div>