import { Dict, Option, Result, Ok, Err } from './result.js';
import { diFetchItem } from './difetch.js';

import { Context } from './direportdetailsscorius.js';

// Models
export interface UserReportDataItem {
	user: string;
	username: string;
	report: string;
	report_name: string;
	timestamp: Date;
	data: ReportData;
}

/*
 * Notice the nesting of the results and the results_summary
 * The query defines what the results will be in the other items.
 * The other items (group_summary, results, results_summary) will
 *   use the key of the Query to group the respective results, filtered
 *   using that query.
 */
export interface ReportData {
	context: Context;
	query: Dict<Query>; // key is query index
	group_summary: Dict<GroupSummaryItem>; // key is query index
	results: Dict<ResultItem>; // key is query index
	results_summary: Dict<ResultsSummaryItem>; // key is query index
}

export type Query = string[];

export interface GroupSummaryItem {
	name: string;
	invited: number;
	response: number;
	data_available: boolean;
}

export type ResultItem = Dict<QuestionResult>; // key is question index

export interface QuestionResult {
	response_type: string;
	question: string;
	count: Dict<number>; // key is option item
	mean: Option<number>;
}

export interface ResultsSummaryItem {
	invited: number;
	response: number;
	data_available: boolean;
}

// Types

export type ReplyItem = 'RESULTS' | 'RESULTS_SUMMARY';

export type Filter = string[];

// Functions

function getUserReportDataItemUrl(report_slug: string, filters: Filter[] | null = null): string {
	let url = '/api/v1/reports/' + report_slug + '/data/';

	let filter_items: string[] = [];
	if (filters !== null) {
		// Filters need to be applied
		let filter_parts: string[] = [];
		for (const filter of filters) {
			filter_parts.push('query=' + filter.join(','));
		}

		const filter_query = filter_parts.join('&');

		url += '?' + filter_query;
	}

	return url;
}

/**
 * Retrieve report data which is optionally filtered
 *
 * @param {string} report_slug, required
 * @param {Filter[]|null} filters, optional, when provided the filter items (which are slugs) in the list will be used as data selector
 * @return Promissed response
 */
export async function getUserReportDataItem(
	report_slug: string,
	filters: Filter[] | null = null
): Promise<Result<UserReportDataItem>> {
	const url = getUserReportDataItemUrl(report_slug, filters);
	const result = await diFetchItem(url);
	if (result.ok === true) {
		// @ts-ignore
		return Ok(result.value.results);
	}
	return result;
}

/**
 * Retrieve multiple report data-items which are optionally filtered
 * Note that if subgroups are requested, the version without subgroups is automatically added to the request.
 *
 * @param {string[]} report_slugs, required
 * @param {string} report_slug, required
 * @param {Filter[]|null} filters, optional, when provided the filter items (which are slugs) in the list will be used as data selector
 * @return Promissed response
 */
export async function getUserReportDataItems(
	report_slugs: string[],
	filters: Filter[] | null = null
): Promise<Result<UserReportDataItem>[]> {
	let urls: string[] = [];
	for (const report_slug of report_slugs) {
		const url = getUserReportDataItemUrl(report_slug, filters);
		urls.push(url);
	}

	// Fetch all urls
	const promises = urls.map((url) =>
		diFetchItem(url)
			.then((result) => {
				if (result.ok) {
					// @ts-ignore
					return Ok(result.value.results);
				} else {
					return result;
				}
			})
			.catch((error) => Err(error.message))
	);

	return Promise.all(promises);
}
