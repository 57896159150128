import { diFetch } from './difetch.js';

/**
 * Ensure that the CSRF Cookie is set
 *
 * @return Promissed response
 */
export async function setCSRF() {
	const url = '/api/sessionauth/set-csrf/';
	await diFetch(url, 'GET', null);
}

/**
 * Login a user via the scorius back-end.
 */
export function login(): string {
	const url = 'https://analytics.scorius-acceptance.nl/login';
	window.location.href = url;
	return url;
}

/**
 * Logout a user
 *
 * @return Promissed response
 */
export async function logout() {
	const url = '/api/v1/scorius/logout/';
	await diFetch(url, 'GET', null);
}
