import { Dict, Option, Result, Ok } from './result.js';
import { diFetchItem } from './difetch.js';

// Models

export interface UserReportDetailsItem {
	schema_name: string;
	report_slug: string;
	report_version: number;
	context: Context;
	constructs: Dict<ConstructItem>; // key is construct-id
	groups: Dict<GroupItem>; // key is group-id
	subgroups: Dict<SubgroupItem>; // key is subgroup-id
}

/// EOF Top Model

export interface Context {
	process_date: string;
	company: Company;
	i18n: I18n;
	default_group: Dict<string>;
	measurement: Measurement;
	mininum_number_of_respondents: number;
}

export interface Company {
	id: number;
	name: string;
	css: Option<string>;
	logo: Option<string>;
}

export interface I18n {
	defaultLang: string;
	i18n: string[];
}

export interface Measurement {
	period: Dict<string>; // key is language-id
	periodType: string;
}

/// EOF Context

export interface ConstructItem {
	id: string;
	name: Dict<string>; // key is language-id
	definition: Dict<string>; // key is language-id
	topics: Dict<Topic>;
	active: boolean;
}

export interface Topic {
	id: string;
	name: Dict<string>; // key is language-id
	definition: Dict<string>; // key is language-id
	items: Dict<TopicItems>; // key is question-id
	active: boolean;
	parentId: string;
}

export interface TopicItems {
	id: number;
	text: Dict<string>; // key is language-id
	responseType: ResponseType;
	active: boolean;
	parentId: string;
}

export interface ResponseType {
	type: string;
	options: unknown; // unknown
}

/// EOF Construct

export interface GroupItem {
	slug: string;
	name: Dict<string>; // key is language-id
	order: Option<number>;
	children: Dict<GroupItem>; // key is group-id
	showInDashboard: boolean;
	parentId: string;
}

export type SubgroupItem = GroupItem;

// Functions

export async function getUserReportDetailsItem(
	report_slug: string
): Promise<Result<UserReportDetailsItem>> {
	const url = '/api/v1/reports/' + report_slug + '/details/';
	const result = await diFetchItem(url);
	if (result.ok === true) {
		// @ts-ignore
		return Ok(result.value.data);
	}
	return result;
}
