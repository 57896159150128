<script lang="ts">
	import type {
		DisplayItem,
		OptionDisplayTreeItem
	} from '$lib/components/pages/components/TableControls/common/DisplayItem';
	import CrossIcon from '$lib/components/pages/components/common/Icons/CrossIcon.svelte';
	import Select from 'svelte-select/Select.svelte';
	import type { Writable } from 'svelte/store';
	import { writable } from 'svelte/store';

	import { defaultGroup, groups, organizationalUnitsSelected } from '$lib/stores/dataStore';
	import { allTranslations, currentLanguage } from '$lib/stores/languageStore.js';

	let groupRadioButtons: DisplayItem[];
	let selectedGroup: string = $defaultGroup;

	$: {
		groupRadioButtons = Object.keys($groups).map((key: string) => {
			return { value: key, label: $groups[key].displayName[$currentLanguage.code], selectable: true };
		});
	}

	const selectedItems: Writable<OptionDisplayTreeItem[]> = writable([]);
	$: selectedItems.update((values: OptionDisplayTreeItem[]) => Object.keys($organizationalUnitsSelected).flatMap((k) => $organizationalUnitsSelected[k]));
	
	const clearSelectedItem = (item: OptionDisplayTreeItem) => {
		organizationalUnitsSelected.update((data) => {
			data[item.dropdownParent] = data[item.dropdownParent].filter((option) => option.value !== item.value);
			return data;
		});
		toggleSelectable(item, true);
	};

	const clearAllSelectedItems = () => {
		selectedItemBoxVisibility.set('hidden');
		organizationalUnitsSelected.update((data) => {
			Object.keys(data).forEach((k) => {
				(data[k] as OptionDisplayTreeItem[]).forEach(item => toggleSelectable(item, true));
				data[k] = [];
			});
			return data;
		});
	};

	const toggleSelectable = (item: OptionDisplayTreeItem, selectable: boolean) => {
		groups.update((current) => {
			item.selectable = selectable;
			let _idx = current[item.dropdownParent].options.findIndex((o)  => o.value === item.value);
			if(_idx > - 1) {
				current[item.dropdownParent].options[_idx] = item;
			}
			return current;
		});
	}

	const valueToBeCleared: Writable<string | null> = writable(null);
	
	const selectItem = (event: CustomEvent) => {
		$valueToBeCleared = null;
		toggleSelectable(event.detail as OptionDisplayTreeItem, false);
		organizationalUnitsSelected.update((data) => {
			(data[event.detail.dropdownParent] as OptionDisplayTreeItem[]).push(event.detail);
			return data;
		});
	};

	const selectedItemBoxVisibility: Writable<string> = writable('hidden');

	const toggleSelectedItemVisibility = () => {
		$selectedItemBoxVisibility = $selectedItemBoxVisibility === 'hidden' ? 'visible' : 'hidden';
	};
</script>

<!-- https://fontawesome.com/search?q=corner&o=r -->

<div class="group-categories">
	{#each groupRadioButtons as group}
		<div class="form-check-inline">
			<input
				class="form-check-input"
				type="radio"
				bind:group={selectedGroup}
				value={group.value}
				name="selectedGroupCategory"
				id={group.value}
			/>
			<label class="form-check-label" for={group.value}>
				{group.label}
			</label>
		</div>
	{/each}
</div>

<div class="group-value-selection">
	{#each groupRadioButtons as group}
		{#if selectedGroup === group.value}
			<Select
				items={$groups[group.value].options}
				placeholder={allTranslations['select']['box1'][$currentLanguage.code]}
				showChevron={true}
				closeListOnChange={true}
				bind:value={$valueToBeCleared}
				on:change={selectItem}
			>
				<div slot="item" let:item let:index>
					<div class="group-selector-item">
						<i class="fa-sharp fa-solid fa-corner fa-rotate-180 custom-corner" />
						{#each Array(item.depth - 1) as _}
							<i class="fa-sharp fa-solid fa-minus custom-minus" />&nbsp;
						{/each}
						<span class="custom-item">{item.originalLabel[$currentLanguage.code]}</span>
					</div>
				</div>
			</Select>
		{/if}
	{/each}
</div>

<div class="selected-items-controls overflow-height-{$selectedItemBoxVisibility}">
	<div class="selected-items-container">
		{#each $selectedItems as item}
			<button class={`item item-${item.className}`} on:click={() => clearSelectedItem(item)}>
				{item.originalLabel[$currentLanguage.code]}
				<CrossIcon />
			</button>
		{/each}
	</div>
	<div class="selected-items-toggle-visibility">
		{#if $selectedItems.length > 0}
			<button class="clear-selected-items" on:click={clearAllSelectedItems}>
				<CrossIcon />
			</button>
		{/if}
		{#if $selectedItems.length > 3}
		<button on:click={toggleSelectedItemVisibility}>
			{#if $selectedItemBoxVisibility === 'hidden'}
				<i class="fa-sharp fa-solid fa-chevrons-down" />
			{:else}
				<i class="fa-sharp fa-solid fa-chevrons-up" />
			{/if}
		</button>
		{/if}
	</div>
</div>
