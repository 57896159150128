<script lang="ts">
    import Tabs from '$lib/components/resultsTab/resultsTabTabs.svelte';
	import { createEventDispatcher } from 'svelte';

    const _dispatch = createEventDispatcher();
  
    function forward(event) {
        _dispatch('switchTabEvent', event.detail);
    }
</script>

<Tabs on:switchTabEvent={forward} />