<script lang="ts">
	const year = new Date().getFullYear();
</script>
<footer class="footer">
	<div class="container-fluid">
		<div class="row text-muted">
			<p class="mb-0">
				© Copyright {year} <a href="http://www.scorius.nl" class="text-muted">Scorius</a>
			</p>
		</div>
	</div>
</footer>