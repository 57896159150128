<script lang="ts">
	import IconButton from "$lib/components/pages/components/TableControls/common/IconButton/IconButton.svelte";
    import ToggleButton from "$lib/components/pages/components/TableControls/common/ToggleButton/ToggleButton.svelte";

    import {
		availableReports,
		dataIsCurrentlyLoading,
		heatmapMode,
		historicBenchmarkMode,
		historicBenchmarkSlug,
        exportTableOptions,
		mainNavTabSelected,
		dashboardFocusReportSlug,
        dashboardFocusReportSlugForBenchmark,
        historicBenchmarksAvailable
	} from '$lib/stores/dataStore';
	import { allTranslations, currentLanguage } from "$lib/stores/languageStore";
	import { createEventDispatcher } from "svelte";
    
    export let activeTab: string = '';

    let showDifferenceWith: boolean = false;
    //UPDATE $historicBenchmarksAvailable deze kan hiervoor worden gebruikt
    $: showDifferenceWith = $dashboardFocusReportSlug !== $dashboardFocusReportSlugForBenchmark;

    const _dispatch = createEventDispatcher();

    // Example how to read events from child component
    const onHeatmapToggle = (event: CustomEvent) => $heatmapMode = event.detail.toggleButtonValue;

    const onToggleLineBars = (event: CustomEvent) => _dispatch('toggleLineBarsEvent', {
            toggle: event.detail.toggleButtonValue
        });

    const onDifferenceToggle = (event: CustomEvent) => $historicBenchmarkMode = !event.detail.toggleButtonValue;

    function updateSelectedHistoricBenchmark(event) {
		const selectedSlug = event.target.value;
		const selectedReport = $availableReports.find((report) => report.slug === selectedSlug);

		if (selectedReport) {
			$historicBenchmarkSlug = selectedReport.slug;
			let toggle = document.getElementById('show-difference-with');
			toggle.checked = true;
			$historicBenchmarkMode = false; //false is on
		} else {
			console.warn('Selected report not found!');
		}
	}

    $: if($mainNavTabSelected === 'tab3') activeTab = 'responseTable';

    const doMaximizeTable = () => $exportTableOptions[$mainNavTabSelected][activeTab].maximize();
    const doMinimizeTable = () => $exportTableOptions[$mainNavTabSelected][activeTab].minimize();
    const doExportTableExcel = () => $exportTableOptions[$mainNavTabSelected][activeTab].exportExcel();
    const doExportTableCSV = () => $exportTableOptions[$mainNavTabSelected][activeTab].exportCSV();

</script>

<div class="table-control-toggles">
    {#if $mainNavTabSelected === 'tab2'}
        {#if  activeTab !== 'results'}
            <ToggleButton name="show-heatmap" label={allTranslations['table.settings']['show-heatmap'][$currentLanguage.code]} on:message={onHeatmapToggle}></ToggleButton>
        {/if}
    
        {#if showDifferenceWith}
            <ToggleButton name="show-difference-with" label={allTranslations['table.settings']['show-difference'][$currentLanguage.code]} on:message={onDifferenceToggle}></ToggleButton>
            <!-- <input type="text" placeholder={differencePlaceholder} name="difference-with-date"> -->
            <select id="select2_historicBenchmark" class="form-control select2 mt-1 mb-1 benchmarkSelectionDropdown" data-toggle="select2" on:change={updateSelectedHistoricBenchmark}>
                {#each $availableReports as measurementReport, i}
                    {#if i !== 0}
                        <option value={measurementReport['slug']}>{measurementReport['name']}</option>
                    {/if}
                {/each}
            </select>
        {/if}
    {/if}
    {#if $mainNavTabSelected === 'tab3'}
    <div class="table-control-toggles">
        <ToggleButton name="toggle-lines-bars" label={allTranslations['table.settings']['toggle-lines-bars'][$currentLanguage.code]} on:message={onToggleLineBars} ></ToggleButton>
    </div>
{/if}
</div>
{#if activeTab !== 'results'}
    <div class="table-control-icons">
        <IconButton title={allTranslations['table.settings']['button-title-maximize-table'][$currentLanguage.code]} classes="fa-regular fa-arrows-maximize" onClick={doMaximizeTable}></IconButton>
        <IconButton title={allTranslations['table.settings']['button-title-minimize-table'][$currentLanguage.code]} classes="fa-regular fa-arrows-minimize" onClick={doMinimizeTable}></IconButton>
        
        <IconButton title={allTranslations['table.settings']['button-title-download-csv'][$currentLanguage.code]} classes="fa fa-file-csv" onClick={doExportTableCSV}></IconButton>
        <IconButton title={allTranslations['table.settings']['button-title-download-excel'][$currentLanguage.code]} classes="fa fa-file-excel" onClick={doExportTableExcel}></IconButton>
    </div>
{/if}
