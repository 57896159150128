export type LinkKey = string;
export type LinkUrl = string;
export type LinkItems = { [key: LinkKey]: LinkUrl };

/**
 * Return the absolute url
 *
 * @param {string} url - Url
 * @return Fully qualified url
 */
export function absoluteUrl(url: string): string {
	let link = document.createElement('a');
	link.href = url;
	return link.protocol + '//' + link.host + link.pathname + link.search;
}

/**
 * Return the item embedded in the url
 *
 * @param {string} item - url append string
 * @param {string} url - base url
 * @return Fully qualified url
 */
function link(item: LinkKey, url: LinkUrl): string {
	const std_url = url.endsWith('/') ? url : url + '/'; // ensure trailing slash is present
	const base_url = absoluteUrl(std_url);
	const linked = base_url + item + '/';
	return linked;
}

/**
 * Return object with the link_items linked
 *
 * @param {T} x - object
 * @param {LinkItems} link_items - object where key == object-item and value is the url append stirng
 * @return Object extended with fully qualified url
 */
export function linkMulti<T>(x: T, link_items: LinkItems): T {
	for (const key in link_items) {
		// @ts-ignore
		x[key] = link(x[key], link_items[key]);
	}
	return x;
}

/**
 * Return the last part of an absolute url
 *
 * @param {string} url - Url
 * @return Last part of the url without slash
 */
export function unlink(url: LinkUrl): string {
	const std_url = url.endsWith('/') ? url.slice(0, -1) : url; // remove trailing slash if present
	const unlinked_parts = std_url.split('/');
	return unlinked_parts[unlinked_parts.length - 1];
}

/**
 * Return object with the link_items linked
 *
 * @param {T} x - object
 * @param {LinkItems} items - array with object keys to unlink
 * @return Object whithout the urls
 */
export function unlinkMulti<T>(x: T, items: LinkKey[]): T {
	for (const item of items) {
		// @ts-ignore
		x[item] = unlink(x[item]);
	}
	return x;
}
