<script lang="ts">
	import { onMount } from 'svelte';
	import { allTranslations, currentLanguage } from '../../stores/languageStore.js';
	import { getAllKeysForGroupAndSubgroups } from '../../utilities/formatDataHelperFunctions.js';
	import { generateGridContentForResponse } from '../../utilities/aggridHelperFunctions.js';
	import {
		availableReports,
		organizationalUnitsSelected,
		subGroupsSelected,
		historicBenchmarkMode,
		historicBenchmarkSlug,
		heatmapMode,
		allReportDataForFetchedGroups,
		standardAmountOfReportsToFetch,
		dashboardFocusReportSlug,
		modalDetailsVisible,
		dataIsCurrentlyLoading,
		serverEnvironment,
		agGridLicense,
		exportTableOptions,
		mainNavTabSelected


	} from '../../stores/dataStore';

	import { get } from 'svelte/store';
	import { Grid } from 'ag-grid-enterprise';
	import { LicenseManager } from 'ag-grid-enterprise';

	LicenseManager.setLicenseKey(agGridLicense);
	import 'ag-grid-community/styles/ag-grid.css';
	import 'ag-grid-community/styles/ag-theme-alpine.css';

	export let focus; //defined in resultatenTab.svelte
	export let tableID = ''; //defined in resultatenTab.svelte
	//Added to see if grid is already present
	let gridApi = null;
	let gridColumnApi = null;

	onMount(() => {
		let idString = '#' + tableID;
		$exportTableOptions[$mainNavTabSelected][tableID] = { exportExcel: function() { export_excel(); }, exportCSV: function() { export_csv(); }, maximize: function() { fitTableToView();}, minimize: function() { fitContentToView();}};

		const gridDiv = document.querySelector(idString);
		//let test = testGrid.createGrid(gridDiv, gridOptions)
		new Grid(gridDiv, gridOptions);
		//gridApi = AgGrid.createGrid(gridDiv, gridOptions);
	});

	let gridOptions = {
		rowData: [{ construct: 'test' }],
		columnDefs: [{ field: 'construct' }],
		suppressFieldDotNotation: true,
		animateRows: true,
		autoSizeStrategy: {
			type: 'fitGridWidth',
			defaultMinWidth: 100,
		
		},
		defaultColDef: {
			sortable: true,
			resizable: true,
			unSortIcon: true,
			menuTabs: ['generalMenuTab'],
			suppressMenu: true,
			wrapHeaderText: true,
			autoHeaderHeight: true
		},
		autoGroupColumnDef: {
			minWidth: 200
		},
		onFirstDataRendered: (params) => {
			gridApi = params.api;
			gridColumnApi = params.columnApi;
			params.api.expandAll();
		},
		onCellClicked: (event) => {
			console.log('Cell was clicked');
			console.log(event);

			// Getting the row id
			const rowId = event.data.id;
			console.log('Row Id: ', rowId);

			// Getting the column id
			const colId = event.column.userProvidedColDef.groupId;
			console.log('Column Id: ', colId);
		},
		onCellMouseOver: (event) => {
			//console.log('Cell was hovered');
			//console.log(event);
			const rowId = event.data.id;
			console.log('Row Id: ', rowId);
			// Getting the column id
			const colId = event.column.userProvidedColDef.field;
			console.log('Column Id: ', colId);
		},
		onCellMouseOut: (event) => {
			const rowId = event.data.id;
			const colId = event.column.userProvidedColDef.field;
		}
	};

	//This is for devMode local
	$: if ($organizationalUnitsSelected) {
		if (!$serverEnvironment) {
			//	console.log('LOCAL DevMode organizationalUnitsSelected changed:', $organizationalUnitsSelected);
			if (gridApi) {
				gridApi.showLoadingOverlay();
				updateTable();
			} else {
				//		console.log('gridApi not yet available');
			}
		}
	}
	//This is for devMode local
	$: if ($subGroupsSelected) {
		if (!$serverEnvironment) {
			//	console.log('LOCAL DevMode subgroups changed:', $subGroupsSelected);
			if (gridApi) {
				updateTable();
			} else {
				//		console.log('gridApi not yet available');
			}
		}
	}

	// Reactive statement to log changes, is not triggert in local devmode.
	$: if ($allReportDataForFetchedGroups) {
		//console.log(($allReportDataForFetchedGroups))
		if (gridApi) {
			if (!$dataIsCurrentlyLoading) {
				updateTable();
			} else {
				console.log('Grid data is currently loading, no action');
			}
		} else {
			//console.log('gridApi not yet available');
		}
	}

	async function updateTable() {
		//console.log('allReportDataForFetchedGroups updated:', $allReportDataForFetchedGroups);
		const reportKeys = Object.keys($allReportDataForFetchedGroups);
		//	console.log('reportKeys:', reportKeys);

		let makeATableWithTheseGroups = await getAllKeysForGroupAndSubgroups(
			$organizationalUnitsSelected,
			$subGroupsSelected
		);
		//console.log('makeATableWithTheseGroups:', makeATableWithTheseGroups);
		let arrayWithSlugsToShow = [];
		//get .slug from the first $standardAmountOfReportsToFetch reports
		let numberOfReportsToShow = get(standardAmountOfReportsToFetch);
		let availableReportsOnServer = get(availableReports);
		for (let i = 0; i < numberOfReportsToShow; i++) {
			// Check if the current index exists in the availableReportsOnServer array
			if (i < availableReportsOnServer.length) {
				arrayWithSlugsToShow.push(availableReportsOnServer[i].slug);
			} else {
				// Break out of the loop if there are no more reports available
				break;
			}
		}

		let newGridContent = await generateGridContentForResponse(
			arrayWithSlugsToShow,
			makeATableWithTheseGroups
		);
		let columnDefs = newGridContent[0];
		let rowData = newGridContent[1];

		gridOptions.api.setColumnDefs(columnDefs);
		gridOptions.api.setRowData(rowData);
	}

	function export_csv() {

		let reportKeys = Object.keys($allReportDataForFetchedGroups);
		reportKeys.sort();
		//console.log(reportKeys);
		//check if there is a 'details' key for each of the reportKeys and then check if there is a 'context' key in the details and then a 'company' key in the context, and then a name key in the company
		let companyName = 'export';
		let contentName='respons';
		let currentMeasurementPeriod = 'data';
		reportKeys.forEach((reportKey) => {
		// Safely access nested properties with optional chaining
		companyName = $allReportDataForFetchedGroups[reportKey]?.details?.context?.company?.name ?? companyName; // Keep the current companyName if the new one is undefined
		currentMeasurementPeriod = $allReportDataForFetchedGroups[reportKey]?.details?.context?.measurement?.period?.value ?? currentMeasurementPeriod; // Keep the current currentMeasurementPeriod if the new one is undefined
		});

		let fileNameForExport=companyName+' - '+contentName+' - '+currentMeasurementPeriod+'.csv';

		gridOptions.api.exportDataAsCsv({
		fileName: fileNameForExport,
    });
	}
	function export_excel() {
		let reportKeys = Object.keys($allReportDataForFetchedGroups);
		reportKeys.sort();
		//console.log(reportKeys);
		//check if there is a 'details' key for each of the reportKeys and then check if there is a 'context' key in the details and then a 'company' key in the context, and then a name key in the company
		let companyName = 'export';
		let contentName='respons';
		let currentMeasurementPeriod = 'data';
		reportKeys.forEach((reportKey) => {
		// Safely access nested properties with optional chaining
		companyName = $allReportDataForFetchedGroups[reportKey]?.details?.context?.company?.name ?? companyName; // Keep the current companyName if the new one is undefined
		currentMeasurementPeriod = $allReportDataForFetchedGroups[reportKey]?.details?.context?.measurement?.period?.value ?? currentMeasurementPeriod; // Keep the current currentMeasurementPeriod if the new one is undefined
		});

	 let fileNameForExport=companyName+' - '+contentName+' - '+currentMeasurementPeriod+'.xlsx';
		gridOptions.api.exportDataAsExcel({
			fileName: fileNameForExport,
		    sheetName: 'Export'
		});
	}

	function fitTableToView() {
		gridOptions.api.sizeColumnsToFit({
			defaultMinWidth: 150,
			columnLimits: [{ key: 'Visual', minWidth: 200 }]
		});
	}
	function fitContentToView() {
		gridColumnApi.autoSizeAllColumns();
		// Specify column width limits

		const columnLimits = [
			{ field: 'Visual', minWidth: 200 }
			//... add more columns with their limits here as necessary
		];
		// Get all columns and apply width limits where necessary
		gridColumnApi.getColumns().forEach((column) => {
			const colId = column.getColId();
			const currentWidth = column.getActualWidth();

			// Check if this column has a specific limit
			const limit = columnLimits.find((limit) => limit.field === colId);
			if (limit && currentWidth < limit.minWidth) {
				// It's below the minimum width, so let's update it
				gridColumnApi.setColumnWidth(colId, limit.minWidth);
			}
		});
	}
</script>

<div class="container-fluid p-0">
	<div id={tableID} class="ag-theme-alpine myGrid" />
</div>

<style>
	#loadingMessage {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	#legendHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.myGrid {
		width: 100%;
		height: 500px; /* minimum height you deem appropriate */
		overflow: auto; /* Important: allows scrollbar appearance */
	}
	.hidden {
		background-color: 'red' !important;
	}

	.ag-theme-alpine {
		--ag-borders: none;
		--ag-row-border-width: 1px;
		--ag-row-border-color: #dee6ed;
		--ag-header-height: 30px;
		--ag-header-background-color: transparent;
		--ag-header-foreground-color: #6c757d;
		font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
			sans-serif;
		--ag-odd-row-background-color: transparent;
		--ag-header-column-resize-handle-color: #262e62;
	}

	.ag-cell.ag-cell-center {
		color: aqua;
	}

	.circle {
		transition: all 0.3s ease; /* Adjust the duration as required */
	}

	.grow {
		/* Adjust the transform scale as required */
		transform: scale(1.5);
	}

	.btn-group {
		display: flex;
		justify-content: flex-end; /* Align to the right */
		gap: 10px; /* Adds space between the icons */
	}

	.btn-group i {
		cursor: pointer; /* Changes the mouse cursor on hover */
		transition: color 0.3s; /* Smooth color transition */
	}

	.btn-group i:hover {
		color: red; /* Color on hover */
	}

	:global(.badge-soft-nochange) {
		background-color: rgba(128, 128, 128, 0.175); /* Light gray with opacity */
		color: #808080; /* Gray */
	}
</style>
