
import { writable } from 'svelte/store';

export const currentLanguage = writable({
  flag: '',
  name: '',
  code: undefined,
});

export const availableLanguagesProgrammedInDashboard = [
  { name: 'Dutch', code: 'nl', flag: 'appstack/img/flags/nl.png' },
  { name: 'English', code: 'en', flag: 'appstack/img/flags/gb.png' },
  { name: 'French', code: 'fr', flag: 'appstack/img/flags/fr.png' },
  { name: 'German', code: 'de', flag: 'appstack/img/flags/de.png' }
];
export const availableLanguagesInDashboardThatCanBeSelected = writable([
]);

//https://blog.stranianelli.com/implementing-multi-language-without-any-library-in-svelte/

export const allTranslations = {
  'nav': {
    'title': {
      'en': "Dashboard",
      'nl': "Dashboard",
      'fr': "Tableau de bord",
      'de': "Armaturenbrett",
    },
    'tab1': {
      'en': "Home",
      'nl': "Home",
      'fr': "Accueil",
      'de': "Startseite",
    },
    'tab2': {
      'en': "Results",
      'nl': "Resultaten",
      'fr': "Résultats",
      'de': "Ergebnisse",
    },
    'tab3': {
      'en': "Response",
      'nl': "Respons",
      'fr': "Réponse",
      'de': "Antwort",
    },
    'tab4': {
      'en': "Help",
      'nl': "Help",
      'fr': "Aide",
      'de': "Hilfe",
    },
    'tab5': {
      'en': "Results",
      'nl': "Resultaten",
      'fr': "Résultats",
      'de': "Ergebnisse",
    },
    'tab6': {
      'en': "Response",
      'nl': "Respons",
      'fr': "Réponse",
      'de': "Antwort",
    },
    'logout': {
      'en': "Logout",
      'nl': "Uitloggen",
      'fr': "Se déconnecter",
      'de': "Ausloggen",
    },
  },
  'Login': {
    'Welkom': {
      'en': "Welcome",
      'nl': "Welkom",
      'fr': "Bienvenue",
      'de': "Willkommen",
    },
    'naamDashboard': {
      'en': "Engagement dashboard",
      'nl': "Engagement dashboard",
      'fr': "Tableau de bord d'engagement",
      'de': "Engagement-Dashboard",
    },
    'please': {
      'en': "Please log in to continue",
      'nl': "Log in om verder te gaan",
      'fr': "Veuillez vous connecter pour continuer",
      'de': "Bitte melden Sie sich an, um fortzufahren",
    },
    'Username': {
      'en': "Username",
      'nl': "Gebruikersnaam",
      'fr': "Nom d'utilisateur",
      'de': "Benutzername",
    },
    'UsernameText': {
      'en': "You received the username by email",
      'nl': "Deze heb je per mail ontvangen",
      'fr': "Vous avez reçu le nom d'utilisateur par email",
      'de': "Sie haben den Benutzernamen per E-Mail erhalten",
    },
    'UsernamePlaceholder': {
      'en': "Please enter your username",
      'nl': "Voer je gebruikersnaam in",
      'fr': "Veuillez entrer votre nom d'utilisateur",
      'de': "Bitte geben Sie Ihren Benutzernamen ein",
    },
    'Password': {
      'en': "Password",
      'nl': "Wachtwoord",
      'fr': "Mot de passe",
      'de': "Passwort",
    },
    'PasswordText': {
      'en': "You received the password by email",
      'nl': "Deze heb je per mail ontvangen",
      'fr': "Vous avez reçu le mot de passe par email",
      'de': "Sie haben das Passwort per E-Mail erhalten",
    },
    'PasswordPlaceholder': {
      'en': "Please enter your password",
      'nl': "Voer je wachtwoord in",
      'fr': "Veuillez entrer votre mot de passe",
      'de': "Bitte geben Sie Ihr Passwort ein",
    }
  },

  "homeTab": {
    "box1Titel": {
      "en": "Engagement monitor",
      "nl": "Engagement monitor",
      "fr": "Moniteur d'engagement",
      "de": "Engagement-Monitor"
    },
    "nes": {
      "en": "The Net Engagement Score (NES) is a score that indicates the distribution of employee engagement.",
      "nl": "De Net Engagement Score (NES) is een score die de verdeling van medewerkersbetrokkenheid aangeeft.",
      "fr": "Le Net Engagement Score (NES) est un score indiquant la répartition de l'engagement des employés.",
      "de": "Der Net Engagement Score (NES) ist ein Score, der die Verteilung des Mitarbeiterengagements anzeigt."
    },
    "box2Titel": {
      "en": "Key results",
      "nl": "Belangrijkste resultaten",
      "fr": "Principaux résultats",
      "de": "Wichtigste Ergebnisse"
    },
    "box2SubTitel": {
      "en": "View the key results of your team every month.",
      "nl": "Bekijk elke maand de belangrijkste resultaten van jouw team.",
      "fr": "Consultez chaque mois les principaux résultats de votre équipe.",
      "de": "Sehen Sie sich jeden Monat die wichtigsten Ergebnisse Ihres Teams an."
    },
    "box3Titel": {
      "en": "View the results for different topics",
      "nl": "Bekijk de resultaten voor verschillende onderwerpen",
      "fr": "Consultez les résultats sur différents sujets",
      "de": "Ergebnisse zu verschiedenen Themen anzeigen"
    },
    "box3Button": {
      "en": "Go to results",
      "nl": "Ga naar resultaten",
      "fr": "Aller aux résultats",
      "de": "Zu den Ergebnissen gehen"
    },
    "box4Titel": {
      "en": "Discover how many employees have completed the questionnaire",
      "nl": "Ontdek hoeveel medewerkers de vragenlijst ingevuld hebben",
      "fr": "Découvrez combien d'employés ont rempli le questionnaire",
      "de": "Erfahren Sie, wie viele Mitarbeiter den Fragebogen ausgefüllt haben"
    },
    "box4Button": {
      "en": "Go to response",
      "nl": "Ga naar repons",
      "fr": "Aller à la réponse",
      "de": "Zur Antwort gehen"
    }
  },
  'resultatenTab': {
    'tiles': {
      'nes.low': {
        'en': "Disengaged",
        'nl': "Disengaged",
        'fr': "Disengaged",
        'de': "Disengaged"
      },
      'nes.neutral': {
        'en': "Engaged",
        'nl': "Engaged",
        'fr': "Engaged",
        'de': "Engaged",
      },
      'nes.high': {
        'en': "Highly engaged",
        'nl': "Highly engaged",
        'fr': "Highly engaged",
        'de': "Highly engaged",
      }
    },
    'box1Titel': {
      'en': "Select your team or component",
      'nl': "Selecteer jouw team of onderdeel",
      'fr': "Sélectionnez votre équipe ou composant",
      'de': "Wählen Sie Ihr Team oder Komponente",
    },
    'box1SubTitel': {
      'en': "You can select one or several",
      'nl': "Je kan er één of meerdere selecteren",
      'fr': "Vous pouvez en sélectionner un ou plusieurs",
      'de': "Sie können eins oder mehrere auswählen",
    },
    'box2Titel': {
      'en': "Click to view results of subgroups",
      'nl': "Klik om ook resultaten van subgroepen te zien:",
      'fr': "Cliquez pour voir les résultats des sous-groupes",
      'de': "Klicken Sie, um die Ergebnisse von Untergruppen zu sehen",
    },
    'box2SubTitel': {
      'en': "You can select multiple subgroups here",
      'nl': "Je kan hier meerdere subgroepen kiezen",
      'fr': "Vous pouvez sélectionner plusieurs sous-groupes ici",
      'de': "Hier können Sie mehrere Untergruppen auswählen",
    },
    'box3Titel': {
      'en': "Choose the results you want to see",
      'nl': "Kies welke resultaten je wilt zien:",
      'fr': "Choisissez les résultats que vous souhaitez voir",
      'de': "Wählen Sie die Ergebnisse, die Sie sehen möchten",
    },
    'box3SubTitel': {
      'en': "",
      'nl': "",
      'fr': "",
      'de': "",
    },
    'tab1': {
      'en': "Main results",
      'nl': "Belangrijkste resultaten",
      'fr': "Résultats principaux",
      'de': "Hauptergebnisse",
    },
    'tab2': {
      'en': "Steering",
      'nl': "Sturing",
      'fr': "Direction",
      'de': "Steuerung",
    },
    'tab3': {
      'en': "Well-being",
      'nl': "Welzijn",
      'fr': "Bien-être",
      'de': "Wohlbefinden",
    },
    'tab4': {
      'en': "History",
      'nl': "Historie",
      'fr': "Histoire",
      'de': "Geschichte",
    },
    'tab5': {
      'en': "Additional topics",
      'nl': "Aanvullende onderwerpen",
      'fr': "Sujets supplémentaires",
      'de': "Zusätzliche Themen",
    },
    'tab6': {
      'en': "Working environment",
      'nl': "Werkklimaat",
      'fr': "Environnement de travail",
      'de': "Arbeitsumfeld",
    },
    'tab7': {
      'en': "All results",
      'nl': "Alle resultaten",
      'fr': "Tous les résultats",
      'de': "Alle Ergebnisse",
    },
    'tab2_demo': {
      'en': "Basic questions",
      'nl': "Basis vragen",
      'fr': "Questions de base",
      'de': "Grundfragen",
    },
    'tab3_demo': {
      'en': "Additional questions",
      'nl': "Aanvullende vragen",
      'fr': "Questions supplémentaires",
      'de': "Zusätzliche Fragen",
    },
    'heatmap': {
      'en': "Show heatmap",
      'nl': "Toon heatmap",
      'fr': "Montrer la carte thermique",
      'de': "Heatmap anzeigen",
    },
    'benchmark1': {
      'en': "Show difference",
      'nl': "Toon verschil",
      'fr': "Montrer la différence",
      'de': "Unterschied anzeigen",
    },
    'benchmark2': {
      'en': "with:",
      'nl': "met:",
      'fr': "avec:",
      'de': "mit:",
    },
    'export': {
      'en': "Export data",
      'nl': "Exporteer data",
      'fr': "Exporter les données",
      'de': "Daten exportieren",
    },
    "legend": {
      'explanation1': {
        'en': "The data shown is from measurement:",
        'nl': "De getoonde data is van meting:",
        'fr': "Les données affichées proviennent de la mesure:",
        'de': "Die angezeigten Daten stammen aus der Messung:"
      },
      'explanation2': {
        'en': "no data if n is smaller than 5:",
        'nl': "geen data als n kleiner is dan 5:",
        'fr': "pas de données si n est inférieur à 5:",
        'de': "kleine Daten, wenn n kleiner als 5 ist:"

      },
    },
    'tableButtons': {
      'screenFit': {
        'en': "fit table to screen",
        'nl': "pas tabel aan aan scherm",
        'fr': "ajuster le tableau à l'écran",
        'de': "tabelle an Bildschirm anpassen",
      },
      "contentFit": {
        'en': "fit table to content",
        'nl': "pas tabel aan aan inhoud",
        'fr': "ajuster le tableau au tableau",
        'de': "tabelle an Tabelle anpassen",
      },
    },
    "noDataCard": {
      "en": "No data available",
      "nl": "Geen data beschikbaar",
      "fr": "Pas de données disponibles",
      "de": "Keine Daten verfügbar",

    }


  },
  'responseTab': {
    'box1Titel': {
      'en': "Response development over time (%)",
      'nl': "Respons ontwikkeling over tijd (%)",
      'fr': "Développement de la réponse au fil du temps (%)",
      'de': "Antwortentwicklung im Laufe der Zeit (%)",
    },
    'box2Titel': {
      'en': "Current response per target group",
      'nl': "Huidige respons per doelgroep",
      'fr': "Réponse actuelle par groupe cible",
      'de': "Aktuelle Antwort pro Zielgruppe",

    },
    'lineOrBar': {
      'en': "Show bars instead of lines",
      'nl': "Toon balken in plaats van lijnen",
      'fr': "Afficher des barres au lieu de lignes",
      'de': "Balken anstelle von Linien anzeigen",
    },
    'tabelHeader1': {
      'en': "Team",
      'nl': "Team",
      'fr': "Équipe",
      'de': "Team",
    },
    'tabelHeader2': {
      'en': "Invited",
      'nl': "Uitgenodigd",
      'fr': "Invité",
      'de': "Eingeladen",
    },
    'tabelHeader3': {
      'en': "Participated",
      'nl': "Meegedaan",
      'fr': "Participé",
      'de': "Teilgenommen",
    },
    'tabelHeader4': {
      'en': "% response",
      'nl': "% respons",
      'fr': "% de réponse",
      'de': "% Antwort",
    },
    'tooltipRespons': {
      'en': "Response",
      'nl': "Respons",
      'fr': "Réponse",
      'de': "Antwort",

    },
    'tooltipInvited': {
      'en': "Invited",
      'nl': "Uitgenodigd",
      'fr': "Invité",
      'de': "Eingeladen",
    },
    'tooltipParticipated': {
      'en': "Participated",
      'nl': "Ingevuld",
      'fr': "Participé",
      'de': "Teilgenommen",
    },
  },

  'subgroupButtons': {
    'jarenindienst': {
      'en': "Years in service",
      'nl': "Jaren in dienst",
      'fr': "Années de service",
      'de': "Dienstjahre",
    },
    'dienstverband': {
      'en': "Employment contract",
      'nl': "Dienstverband",
      'fr': "Contrat de travail",
      'de': "Arbeitsvertrag",
    },
    'leeftijd': {
      'en': "Age",
      'nl': "Leeftijd",
      'fr': "Âge",
      'de': "Alter",
    },
    'geslacht': {
      'en': "Gender",
      'nl': "Geslacht",
      'fr': "Genre",
      'de': "Geschlecht",
    },
    'functienaam': {
      'en': "Function name",
      'nl': "Functienaam",
      'fr': "Nom de fonction",
      'de': "Funktionsname",
    }
  },
  'select': {
    'box1': {
      'en': "Type or scroll to search",
      'nl': "Typ of scroll om te zoeken",
      'fr': "Tapez ou faites défiler pour rechercher",
      'de': "Tippen oder scrollen zum Suchen",
    },
    'geenData': {
      'en': "no data available",
      'nl': "geen data beschikbaar",
      'fr': "pas de données disponibles",
      'de': "keine Daten verfügbar",

    },
    'onbekendData': {
      'en': "unknown if data is available",
      'nl': "onbekend of data beschikbaar is",
      'fr': "inconnu si des données sont disponibles",
      'de': "unbekannt, ob Daten verfügbar sind",
    }
  },

  "resultatenSubTab1": {
    "titel": {
      "en": "These are the key results for the component selected in step 1.",
      "nl": "Dit zijn de belangrijkste resultaten voor het bij stap 1 gekozen onderdeel.",
      "fr": "Ce sont les principaux résultats pour le composant sélectionné à l'étape 1.",
      "de": "Dies sind die wichtigsten Ergebnisse für den in Schritt 1 ausgewählten Bestandteil."
    },
    "vorigeMeting": {
      "en": "Since the previous measurement",
      "nl": "Sinds vorige meting",
      "fr": "Depuis la dernière mesure",
      "de": "Seit der letzten Messung"
    },
    "Werkklimaat": {
      "en": "Work Climate",
      "nl": "Werkklimaat",
      "fr": "Climat de travail",
      "de": "Arbeitsklima"
    },
    "Sturing": {
      "en": "Guidance",
      "nl": "Sturing",
      "fr": "Orientation",
      "de": "Steuerung"
    },
    "Welzijn": {
      "en": "Well-being",
      "nl": "Welzijn",
      "fr": "Bien-être",
      "de": "Wohlbefinden"
    },
    "Respons": {
      "en": "Response",
      "nl": "Respons",
      "fr": "Réponse",
      "de": "Antwort"
    },
    'demo2': {
      'en': "Important topic 1",
      'nl': "Important topic 1",
      'fr': "Important topic 1",
      'de': "Important topic 1",
    },
    'demo3': {
      'en': "Important topic 2",
      'nl': "Important topic 2",
      'fr': "Important topic 2",
      'de': "Important topic 2",
    },
    'demo4': {
      'en': "Important topic 3",
      'nl': "Important topic 3",
      'fr': "Important topic 3",
      'de': "Important topic 3",
    }
  },

  "grid": {
    'construct': {
      'en': "Construct",
      'nl': "Construct",
      'fr': "Construire",
      'de': "Konstrukt",
    },
    'vraag': {
      "en": "Question",
      "nl": "Vraag",
      "fr": "Question",
      "de": "Frage"
    },
    'onderwerp': {
      "en": "Topic",
      "nl": "Onderwerp",
      "fr": "Sujet",
      "de": "Thema"
    },
    "visueel": {
      "en": "Visual",
      "nl": "Visueel",
      "fr": "Visuel",
      "de": "Visuell"
    },
    "group": {
      "en": "Group",
      "nl": "Groep",
      "fr": "Groupe",
      "de": "Gruppe"
    },
    "noData": {
      "en": "no data",
      "nl": "geen data",
      "fr": "pas de données",
      "de": "keine Daten"
    },
  },

  "modal": {
    "title1": {
      'nl': 'Welke vraag is er gesteld:',
      'en': 'What question has been asked:',
      'fr': 'Quelle question a été posée:',
      'de': 'Welche Frage wurde gestellt:'

    },
    "titleChart1": {
      'nl': 'Ontwikkeling score over tijd',
      'en': 'Development score over time',
      'fr': 'Score de développement au fil du temps',
      'de': 'Entwicklung des Scores im Laufe der Zeit'
    },
    "titleChart2": {
      'nl': 'Verdeling antwoorden (%) voor ',
      'en': 'Distribution of answers (%) for ',
      'fr': 'Distribution des réponses (%) pour ',
      'de': 'Verteilung der Antworten (%) für '
    },
    "titleChart3": {
      'nl': ' meting ',
      'en': ' measurement ',
      'fr': ' mesure ',
      'de': ' Messung '
    },

    "close": {
      "en": "Close",
      "nl": "Sluiten",
      "fr": "Fermer",
      "de": "Schließen"
    }
  },
  "help": {
    "filters": {
      "nl": "Filters",
      "en": "Filters",
      "fr": "Filtres",
      "de": "Filter"
    },
    "table.settings": {
      "nl": "Tabel instellingen",
      "en": "Table settings",
      "fr": "Paramètres de la table",
      "de": "Tabelleneinstellungen"
    },
    "results": {
      "nl": "Resultaten",
      "en": "Results",
      "fr": "Résultats",
      "de": "Ergebnisse"
    },
    "results.main.results": {
      "nl": "Belangrijkste resultaten",
      "en": "Main results",
      "fr": "Résultats principaux",
      "de": "Hauptergebnisse"
    },
    "results.constructs.questions": {
      "nl": "Onderzoeksvragen per construct",
      "en": "Research questions by construct",
      "fr": "Questions de recherche par construit",
      "de": "Forschungsfragen nach Konstrukt"
    },
    "results.question.results": {
      "nl": "Detail uitkomsten per vraag",
      "en": "Detailed outcomes by question",
      "fr": "Résultats détaillés par question",
      "de": "Detaillierte Ergebnisse nach Frage"
    },
    "results.table": {
      "nl": "Tabel",
      "en": "Table",
      "fr": "Tableau",
      "de": "Tabelle"
    },
    "response": {
      "nl": "Response pagina",
      "en": "Response page",
      "fr": "Page de réponse",
      "de": "Antwortseite"
    },
    "extra.information": {
      "nl": "Aanvullende informatie",
      "en": "Additional information",
      "fr": "Informations supplémentaires",
      "de": "Zusätzliche Informationen"
    },
    "extra.information.actions.interventions": {
      "nl": "Acties en Interventies",
      "en": "Actions and Interventions",
      "fr": "Actions et interventions",
      "de": "Aktionen und Interventionen"
    },
    "extra.information.nes": {
      "nl": "Netto Engagement Score (NES)",
      "en": "Net Engagement Score (NES)",
      "fr": "Score d'engagement net (NES)",
      "de": "Netto-Engagement-Score (NES)"
    },
    "extra.information.response.rate": {
      "nl": "Responspercentage",
      "en": "Response rate",
      "fr": "Taux de réponse",
      "de": "Antwortquote"
    },
    "support": {
      "nl": "Support",
      "en": "Support",
      "fr": "Support",
      "de": "Unterstützung"
    },
  },
  "subgroups": {
    "title": {
      "nl": "Subgroepen",
      "en": "Subgroups",
      "fr": "Sous-groupes",
      "de": "Untergruppen"
    },
    "select.placeholder": {
      "nl": "Selecteer 1 of meerdere subgroepen",
      "en": "Select one or more subgroups",
      "fr": "Sélectionnez un ou plusieurs sous-groupes",
      "de": "Wählen Sie eine oder mehrere Untergruppen"
    },
    "select.group.first": {
      "nl": "Selecteer links eerste een groep",
      "en": "Select first a group on the left",
      "fr": "Sélectionnez d'abord un groupe à gauche",
      "de": "Wählen Sie zuerst eine Gruppe auf der linken Seite"
    }
  },
  "table.settings": {
    "show-heatmap": {
      "nl": "Toon heatmap",
      "en": "Show heatmap",
      "fr": "Afficher la carte thermique",
      "de": "Heatmap anzeigen"
    },
    "show-difference": {
      "nl": "Toon verschil met",
      "en": "Show difference with",
      "fr": "Afficher la différence avec",
      "de": "Unterschied anzeigen mit"
    },
    "show-difference-placeholder": {
      "nl": "Selecteer een datum",
      "en": "Select a date",
      "fr": "Sélectionnez une date",
      "de": "Wählen Sie ein Datum"
    },
    "button-title-maximize-table": {
      "nl": "Maximaliseer tabel",
      "en": "Maximize table",
      "fr": "Maximiser le tableau",
      "de": "Tabelle maximieren"
    },
    "button-title-minimize-table": {
      "nl": "Minimaliseer tabel",
      "en": "Minimize table",
      "fr": "Minimiser le tableau",
      "de": "Tabelle minimieren"
    },
    "button-title-download-csv": {
      "nl": "Download csv",
      "en": "Download csv",
      "fr": "Télécharger csv",
      "de": "CSV herunterladen"
    },
    "button-title-download-excel": {
      "nl": "Download excel",
      "en": "Download excel",
      "fr": "Télécharger excel",
      "de": "Excel herunterladen"
    },
    "toggle-lines-bars": {
      "nl": "Switch tussen lijn-/staafdiagram",
      "en": "Toggle lines/bars",
      "fr": "Basculer entre lignes/barres",
      "de": "Zwischen Linien/Balken umschalten"
    },
    "title": {
      "nl": "Tabel settings",
      "en": "Table settings",
      "fr": "Paramètres de tableau",
      "de": "Tabelleneinstellungen"
    }
  }
}