<script lang="ts">
    import TableControls from "$lib/components/pages/components/TableControls/TableControls.svelte";
    import Table from "$lib/components/pages/components/Table/Table.svelte";
	import ResponsTab from "$lib/components/responseTab/responsTab.svelte";

    let activeTab: string = '';
    let showBars: boolean = false;
  
    function onSwitchTabEvent(event: CustomEvent) {
       console.log(event);
       activeTab = event.detail.tabs.find((t) => t.id === event.detail.activeTabId).keyInDataObject;
    }

    function onToggleLineBarsEvent(event: CustomEvent) {
        console.log('onToggleLineBarsEvent', event);
        showBars = event.detail.toggle;
    }
</script>

<main class="content">
    <div class="container-fluid container-results">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <TableControls {activeTab} on:toggleLineBarsEvent={onToggleLineBarsEvent}></TableControls>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <ResponsTab {showBars} />
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>